@import 'variables';
@import 'platform-colors';
@import 'utils/spacing';

$material-animation-default-timing: 400ms;
$material-animation-default-function: cubic-bezier(0.25, 0.8, 0.25, 1);

$border: 1px solid $neutral-light;
$headerPaddingHorizontal: 24px;
$headerPaddingVerital: 16px;

@keyframes flipUp {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(180deg);
    }
}

@keyframes flipDown {
    from {
        transform: rotateZ(180deg);
    }
    to {
        transform: rotateZ(0deg);
    }
}

.ui.segment:not(.basic),
ui-card {
    mat-expansion-panel {
        border: {
            left: $border;
            right: $border;
        }

        box-shadow: none !important;

        &:first-of-type {
            border-top: $border;
        }
    }
}

mat-expansion-panel.mat-expansion-panel:not(.mat-expanded)
    mat-expansion-panel-header.mat-expansion-panel-header:not([aria-disabled='true']) {
    &:hover {
        background-color: $primary-max-lightest;

        mat-panel-title,
        mat-panel-description {
            color: var(--primary-600);

            html.dark & {
                color: var(--primary-800);
            }
        }
    }
}

mat-expansion-panel {
    box-shadow: var(--segment-box-shadow);
    border-bottom: $border;
    border-radius: 0 !important;

    &:first-of-type {
        border-top-left-radius: $segment-border-radius !important;
        border-top-right-radius: $segment-border-radius !important;
    }

    &:last-of-type {
        border-bottom-left-radius: $segment-border-radius !important;
        border-bottom-right-radius: $segment-border-radius !important;
    }

    &.mat-expansion-panel-spacing {
        margin: 0;
    }

    &.borderless {
        border: none !important;

        mat-expansion-panel-header {
            padding: 0;
            height: 100% !important;
            &:not(.w-100) {
                width: fit-content;
            }

            .mat-content {
                margin-right: 0 !important;
                padding-right: calc-spacing(6); // Proper caret placement
            }

            &.hide-toggle .mat-content {
                padding-right: calc-spacing(1);
            }

            &:hover {
                background-color: transparent !important; // No background color change on hover
            }
        }

        .mat-expansion-panel-body {
            padding: 0;
        }
    }
}

mat-expansion-panel-header {
    position: relative;
    padding: $headerPaddingVerital $headerPaddingHorizontal;

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description {
        color: var(--grey-900);
        font-weight: lighter;
    }

    // To implement our own custom icons on this instead of the default material ones
    &:not(.hide-toggle):after {
        background-color: var(--grey-900);
        mask-image: url('/assets/shape-icons/Icon_Chevron_Down.svg');
        content: ' ';
        height: 16px;
        width: 16px;
        position: absolute;
        right: $headerPaddingHorizontal;
        animation: flipDown $material-animation-default-timing $material-animation-default-function forwards;
    }

    &.link {
        font-size: $font-size;

        &:after {
            background-color: $primary;
        }
    }

    &.mat-expanded {
        &:after {
            animation: flipUp $material-animation-default-timing $material-animation-default-function forwards;
        }
    }
}

:root {
    --mat-expansion-container-background-color: var(--card-background-color);
    --mat-expansion-container-text-color: var(--grey-900);
    --mat-expansion-header-disabled-state-text-color: var(--grey-500);
}
