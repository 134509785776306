mat-form-field {
    ui-button {
        display: inline-flex !important;
        position: absolute;
        height: var(--field-min-height);
        // To align to the right of the form field
        top: 0;
        right: calc(var(--field-horizontal-padding) * -1);
    }
}
