@import 'platform-colors';

button.mdc-switch {
    --mdc-switch-state-layer-size: 48px;
    --mdc-switch-handle-surface-color: var(--card-background-color); // Default handle surface color beneath overlay

    // Unselected colors
    --mdc-switch-unselected-handle-color: var(--card-background-color);
    --mdc-switch-unselected-hover-state-layer-color: var(--card-background-color);
    --mdc-switch-unselected-pressed-state-layer-color: var(--card-background-color);
    --mdc-switch-unselected-focus-handle-color: #{$neutral-lighter};
    --mdc-switch-unselected-hover-handle-color: var(--card-background-color);
    --mdc-switch-unselected-pressed-handle-color: #{$neutral-light};
    --mdc-switch-unselected-track-color: #{$neutral-dark};
    --mdc-switch-unselected-focus-track-color: #{$neutral-dark};
    --mdc-switch-unselected-hover-track-color: #{$neutral-darker};
    --mdc-switch-unselected-pressed-track-color: #{$neutral-dark};
    --mdc-switch-unselected-hover-state-layer-color: #{$neutral-darker};
    --mdc-switch-unselected-focus-state-layer-color: #{$neutral-darker};
    --mdc-switch-unselected-pressed-state-layer-color: #{$neutral-darker};

    // Selected colors
    --mdc-switch-selected-handle-color: var(--card-background-color);
    --mdc-switch-selected-hover-state-layer-color: var(--card-background-color);
    --mdc-switch-selected-pressed-state-layer-color: var(--card-background-color);
    --mdc-switch-selected-focus-handle-color: #{$primary-max-lightest};
    --mdc-switch-selected-hover-handle-color: var(--card-background-color);
    --mdc-switch-selected-pressed-handle-color: color-mix(in hsl, $primary, transparent 80%);
    --mdc-switch-selected-track-color: #{$primary};
    --mdc-switch-selected-focus-track-color: #{$primary};
    --mdc-switch-selected-hover-track-color: #{$primary-dark};
    --mdc-switch-selected-pressed-track-color: #{$primary};
    --mdc-switch-selected-hover-state-layer-color: #{$primary};
    --mdc-switch-selected-focus-state-layer-color: #{$primary};
    --mdc-switch-selected-pressed-state-layer-color: #{$primary};

    // Disabled colors
    --mdc-switch-disabled-selected-handle-color: var(--card-background-color);
    --mdc-switch-disabled-unselected-handle-color: var(--card-background-color);
    --mdc-switch-disabled-selected-track-color: #{$neutral};
    --mdc-switch-disabled-unselected-track-color: #{$neutral};
    --mdc-switch-unselected-focus-state-layer-color: #{$neutral};
    --mdc-switch-disabled-track-opacity: 0.5;

    // Track dimensions
    --mdc-switch-track-height: 24px;
    --mdc-switch-track-width: 48px;
    --mdc-switch-track-shape: #{calc-spacing(4)}; // equates to border-radius

    // Hide icon
    .mdc-switch__icons {
        display: none;
    }

    // Adds additional padding between circle and track
    .mdc-switch__handle-track {
        transform: translateX(2px);
    }
    &.mdc-switch--selected {
        .mdc-switch__handle-track {
            transform: translateX(calc(100% - 2px));
        }
    }

    &:focus {
        // Adds ripple on focus (default is only on hover)
        .mdc-switch__ripple::after {
            opacity: 0.15;
        }

        // Ensures hover handle color takes precedent over focus handle color (default is inverse)
        &:hover:not(:active) {
            .mdc-switch__ripple::after {
                opacity: 0.15;
            }

            &.mdc-switch--selected {
                .mdc-switch__handle::after {
                    background: var(--mdc-switch-selected-hover-handle-color);
                }
            }
            &.mdc-switch--unselected {
                .mdc-switch__handle::after {
                    background: var(--mdc-switch-unselected-hover-handle-color);
                }
            }
        }
    }

    // Shrink toggles in mat-menus to better fit
    [mat-menu-item] & {
        transform: scale(0.7);
    }
}

.mat-mdc-slide-toggle .mdc-switch--disabled + label {
    color: var(--field-disabled-font-color);
}
