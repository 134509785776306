/**
		 * UI Upload
		 */
.ui.upload {
    display: block;
    margin: 0 auto;
    width: 350px;
    height: 100px;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: 300;
    font-family: $font-family;
    border-radius: $small-border-radius;
    border: 1px dashed color-mix(in hsl, var(--black), transparent 80%);
    text-align: center;
    padding: 1em;
    transition: 0.3s;
    img,
    .big.icon {
        width: 40px;
        height: 40px;
        opacity: 0.3;
        margin-top: 3px;
        transition: 0.3s;
    }
    .big.icon {
        display: none;
    }
    label {
        font-size: 15px;
        line-height: 20px;
        display: block;
        width: 100%;
        text-align: center;
        &:hover {
            cursor: pointer;
        }
    }
    [type='file'] {
        display: none;
    }
    &:hover {
        cursor: pointer;
        border-color: color-mix(in hsl, var(--black), transparent 60%) !important;
        img {
            opacity: 0.4;
        }
    }
    &.drop-target {
        border: 1px solid color-mix(in hsl, var(--black), transparent 60%) !important;
    }
}

.inline.field {
    .ui.upload {
        margin: 0;
        display: inline-block;
        height: 35px;
        width: 250px;
        padding: 1px 9px;
        vertical-align: middle;
        img,
        .big.icon {
            float: left;
            height: 28px;
            width: 28px;
            margin: 0;
        }
        label {
            overflow: hidden;
            text-overflow: ellipsis;
            float: left;
            height: 32px;
            text-align: left;
            padding-left: 0.5em;
            line-height: 32px !important;
        }
    }
}
