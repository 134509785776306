@import 'responsive';

.mat-menu-content {
    &:not(:empty) {
        padding: var(--dropdown-padding);
    }

    .mat-menu-item {
        height: auto;
        padding: var(--dropdown-item-padding);
        line-height: normal;
        color: var(--grey-900);

        @media (max-width: $small-breakpoint) {
            height: 36px;
        }

        &:has(.mat-menu-submenu-icon) {
            padding-right: calc(var(--dropdown-item-horizontal-padding) + 1rem);
        }

        .mat-menu-submenu-icon,
        .mat-icon-no-color {
            color: var(--grey-900);
        }

        &.tab-bar-item:not(.active):hover,
        &.cdk-keyboard-focused:not([disabled]),
        &:hover:not([disabled]) {
            background-color: var(--dropdown-item-hover-background-color);
            color: var(--dropdown-item-hover-font-color);
        }

        &[aria-checked='true'] {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--dropdown-item-selected-background-color);
            color: var(--dropdown-item-selected-font-color);

            .mat-menu-submenu-icon,
            .mat-icon-no-color {
                color: var(--dropdown-item-selected-font-color);
                margin-right: 0;
                margin-left: 1.5rem;
            }
        }

        &[disabled] {
            color: var(--grey-600);

            .mat-menu-submenu-icon,
            .mat-icon-no-color {
                color: var(--grey-600);
            }
        }
    }
}

.mat-menu-panel {
    min-height: 0 !important;
    max-width: var(--mat-menu-panel-max-width, 312px) !important;
    background-color: var(--dropdown-background-color);
    border-radius: var(--dropdown-border-radius);
}

.material-meatball-menu-button {
    border: none;
    background-color: transparent;
    outline: none !important;
    cursor: pointer;
    padding: 0;
    padding-left: 8px;
    img {
        width: 20px;
        margin-bottom: 1px;
    }
}

.mat-elevation-z4 {
    box-shadow: var(--dropdown-box-shadow);
}
