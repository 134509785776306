:root {
    --side-bar-background-color: var(--card-background-color);
    --side-bar-item-hover-font-color: var(--primary-600);
    --side-bar-item-selected-background-color: var(--primary-100);
    --side-bar-item-selected-font-color: var(--primary-600);
    --side-bar-item-selected-hover-font-color: var(--primary-600);
}

html.dark {
    --side-bar-background-color: var(--grey-100);
    --side-bar-item-hover-font-color: var(--primary-700);
    --side-bar-item-selected-background-color: var(--grey-400);
    --side-bar-item-selected-font-color: var(--white);
    --side-bar-item-selected-hover-font-color: var(--white);
}
