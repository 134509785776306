@import 'platform-colors';

$radioCircleSize: 16px;

mat-radio-group.vertical {
    display: flex;
    flex-direction: column;
}

mat-radio-group:not(.vertical) {
    mat-radio-button + mat-radio-button {
        margin-left: 16px;
    }
}

.mat-mdc-radio-button.mat-primary {
    --mdc-radio-state-layer-size: var(--radio-button-size);

    // Selected state
    --mdc-radio-selected-icon-color: var(--radio-button-selected-inner-circle-color);
    --mdc-radio-selected-hover-icon-color: var(--radio-button-selected-inner-circle-color);
    --mdc-radio-selected-focus-icon-color: var(--radio-button-selected-inner-circle-color);

    // Unselected state
    --mdc-radio-unselected-icon-color: var(--radio-button-default-outer-circle-color);
    --mdc-radio-unselected-hover-icon-color: var(--radio-button-default-outer-circle-color);
    --mdc-radio-unselected-focus-icon-color: var(--radio-button-default-outer-circle-color);

    // Disabled state
    --mdc-radio-disabled-selected-icon-opacity: 1;
    --mdc-radio-disabled-selected-icon-color: var(--radio-button-disabled-inner-circle-color);
    --mdc-radio-disabled-unselected-icon-color: var(--radio-button-disabled-outer-circle-color);

    .mdc-radio {
        width: var(--radio-button-size);
        height: var(--radio-button-size);

        &.mdc-radio--disabled {
            --radio-button-default-background-color: var(--radio-button-disabled-background-color);
            --mdc-radio-selected-icon-color: var(--radio-button-disabled-inner-circle-color);

            + label {
                color: var(--radio-button-disabled-font-color);
            }
        }

        // Focused state
        input:focus + .mdc-radio__background .mdc-radio__outer-circle {
            outline: var(--radio-button-focus-outline-thickness) solid
                var(--radio-button-focus-color, var(--radio-button-selected-inner-circle-color));
            outline-offset: var(--radio-button-focus-outline-offset);
        }
    }

    .mdc-radio__background {
        width: var(--radio-button-size);
        height: var(--radio-button-size);

        // Disable hover ripple
        &::before {
            display: none;
        }

        .mdc-radio__inner-circle {
            // The inner-circle from Angular Material has a scale(0.5) transform meaning that all measurements are halved
            // Therefore we need to perform this calc here for it to end up as the actual prescribed size coming from the CSS variable
            $inner-circle-size: calc(
                (var(--radio-button-size) - (var(--radio-button-selected-inner-circle-width) * 2)) * 2
            );

            top: calc((var(--radio-button-size) - $inner-circle-size) / 2);
            left: calc((var(--radio-button-size) - $inner-circle-size) / 2);
            border-width: 0;
            width: $inner-circle-size;
            height: $inner-circle-size;

            // Our designs use a different circle layout than the one coming from Angular Material, therefore we use a box-shadow instead to accomplish the same thing
            box-shadow: 0px 0px 0px calc((var(--radio-button-selected-inner-circle-width) * 2) - 1px)
                // -1px to make up for the border added by AM (without this, the circle gets pixelated)
                var(--mdc-radio-selected-icon-color);
        }

        .mdc-radio__outer-circle {
            border-width: 1px;
            background-color: var(--radio-button-default-background-color);
        }
    }

    .mdc-form-field > label {
        padding-left: var(--radio-button-label-gap);
        line-height: var(--radio-button-line-height);
    }
}

// Error state
mat-radio-group {
    &.error,
    &.ng-invalid mat-radio-button.mat-mdc-radio-button {
        --mdc-radio-selected-icon-color: var(--radio-button-errored-inner-circle-color);
        --mdc-radio-selected-hover-icon-color: var(--radio-button-errored-inner-circle-color);
        --mdc-radio-selected-focus-icon-color: var(--radio-button-errored-inner-circle-color);
        --mdc-radio-unselected-icon-color: var(--radio-button-errored-outer-circle-color);
        --mdc-radio-unselected-hover-icon-color: var(--radio-button-errored-outer-circle-color);
        --mdc-radio-unselected-focus-icon-color: var(--radio-button-errored-outer-circle-color);
        --radio-button-focus-color: var(--radio-button-errored-outer-circle-color);
    }
}
