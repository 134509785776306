@import 'platform-colors';

.mention-active > a {
    background-color: color-mix(in hsl, var(--primary-600), transparent 95%) !important;

    div > div {
        color: $primary !important;
    }
}

.mention-menu > li {
    & > a {
        padding: 8px !important;
    }

    &:hover {
        background-color: color-mix(in hsl, var(--primary-600), transparent 95%) !important;

        div > div {
            color: $primary !important;
        }
    }
}
