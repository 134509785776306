:root {
    --toast-warning-font-color: var(--grey-900);
    --toast-warning-background-color: var(--warning-600);

    --toast-success-font-color: var(--white);
    --toast-success-background-color: var(--success-600);

    --toast-error-font-color: var(--white);
    --toast-error-background-color: var(--error-600);

    --toast-info-font-color: var(--grey-900);
    --toast-info-background-color: var(--grey-300);
}

html.dark {
    --toast-warning-font-color: var(--warning-100);
    --toast-warning-background-color: var(--warning-600);

    --toast-success-font-color: var(--success-900);
    --toast-success-background-color: var(--success-400);

    --toast-error-font-color: var(--error-900);
    --toast-error-background-color: var(--error-400);
}
