@use 'sass:map';
@import 'platform-colors';
@import 'responsive';

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

/**
 * General Config
 */
$font-family: var(--font-family), sans-serif !important;

// Body sizes
$font-size: var(--body-font-size); // standard
$font-large: 16px;
$font-small: 12px;

// Body line-heights
$line-height: 1.5; //standard
$line-height-large: 1.5;
$line-height-small: 1.5;

$font-color: $primary-font;

$font-black: 900; // Black
$font-bold: 700; // Bold
$font-semibold: 600; // SemiBold
$font-medium: 500; // Medium
$font-regular: 400; // Regular
$font-light: 300; // Light

// Default to use
$font-weight: $font-regular; // Regular

/**
 * Header Config
 */
$header-font-color: var(--grey-900);
$header-sizes: (
    1: 34px,
    2: 24px,
    3: 18px,
    4: 16px,
    5: 14px,
    6: 12px,
);
$header-line-heights: (
    1: 1.2353,
    2: 1.25,
    3: 1.22,
    4: 1.25,
    5: 1.2857,
    6: 1.25,
);
$header-weights: (
    1: var(--heading-level-1-font-weight),
    2: var(--heading-level-2-font-weight),
    3: var(--heading-level-3-font-weight),
    4: var(--heading-level-4-font-weight),
    5: var(--heading-level-5-font-weight),
    6: var(--heading-level-6-font-weight),
);

/**
 * Header Mobile
 */
$mobile-header-sizes: (
    1: 24px,
    2: 20px,
    3: 18px,
    4: 16px,
    5: 14px,
    6: 12px,
);
$mobile-header-line-heights: (
    1: 1.25,
    2: 1.25,
    3: 1.22,
    4: 1.25,
    5: 1.2857,
    6: 1.25,
);
$mobile-header-weights: (
    1: 500,
    2: 500,
    3: 500,
    4: 500,
    5: 500,
    6: 500,
);

@for $i from 1 through 6 {
    %heading-#{$i} {
        font-size: map.get($header-sizes, $i);
        line-height: map.get($header-line-heights, $i);
        font-weight: map.get($header-weights, $i);

        @media screen and (max-width: $small-breakpoint) {
            font-size: map.get($mobile-header-sizes, $i);
            line-height: map.get($mobile-header-line-heights, $i);
            font-weight: map.get($mobile-header-weights, $i);
        }
    }
}
