@import 'variables';
@import 'platform-colors';

$label-blue-background-color: $primary;
$label-light-blue-background-color: $primary-lightest;

/**
         * UI Label
         */
.ui.label {
    border-radius: 9.5px;
    color: $font-color;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: nowrap;

    &.blue {
        background-color: $label-blue-background-color !important;
    }

    &.light-blue {
        background-color: $label-light-blue-background-color !important;
    }

    &.green {
        background-color: $success !important;
    }

    &.red {
        background-color: $error-light !important;
    }

    &.yellow {
        background-color: $warning !important;
        color: $black !important;
    }

    &.disabled {
        background-color: $neutral-light !important;
        color: $neutral-darker !important;
        opacity: 1;
    }
}

.ui.labeled.input .ui.label {
    text-transform: none;
}

.ui.label.fluid {
    text-align: center;
}

.ui.label.large {
    padding: 0.5em;
}

.ui.tiny.label.circular {
    font-size: 0.5em;
    margin-right: 1em;
}
