@import 'platform-colors';
@import 'z-index';
@import 'utils/spacing';

@mixin loadable() {
    $spinner-size: var(--spinner-size, 48px);
    $spinner-color: $primary;
    $spinner-background: color-mix(in hsl, var(--grey-900), transparent 95%);
    $spinner-thickness: var(--spinner-thickness, 8px);
    $overlay-color: var(--spinner-overlay-color, var(--spinner-overlay-default-color)); // Slightly transparent

    &.loading {
        position: relative;
        cursor: default;
        pointer-events: none;
        text-shadow: none !important;
        color: transparent !important;
        transition: all 0s linear;

        // Ensures enough height for spinner to be visible with a small amount of padding
        min-height: var(--spinner-min-content-width, calc($spinner-size + calc-spacing(8)));
        min-width: var(--spinner-min-content-width, calc($spinner-size + calc-spacing(8)));

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            background: $overlay-color;
            width: 100%;
            height: 100%;
            border-radius: inherit; // Get border radius from parent
            z-index: $loading-dimmer-zindex;
        }

        &:after {
            position: absolute;
            content: '';
            width: $spinner-size;
            height: $spinner-size;
            // Spinner is centered
            top: calc(50% - calc($spinner-size / 2));
            left: calc(50% - calc($spinner-size / 2));
            box-sizing: border-box;
            border-color: $spinner-color $spinner-background $spinner-background $spinner-background;
            border-style: solid;
            border-width: $spinner-thickness;
            border-radius: 50%;
            box-shadow: 0px 0px 0px 1px transparent;
            z-index: $loading-dimmer-zindex + 1;

            animation: segment-spin 0.6s linear;
            animation-iteration-count: infinite;
        }

        // Ensures that we don't show overlapping loading spinners. The highest order spinner will always take precedent (ie. the one in the highest parent element)
        .loading {
            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }
    }

    @keyframes segment-spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}
