simple-snack-bar,
app-snack-bar-message {
    color: $white;

    .success & {
        color: var(--toast-success-font-color);
    }

    .warn & {
        color: var(--toast-warning-font-color);
    }

    .error & {
        color: var(--toast-error-font-color);
    }

    .info & {
        color: var(--toast-info-font-color);
    }
}

snack-bar-container {
    .mat-icon {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        margin-right: 8px;
    }

    &.error {
        background-color: var(--toast-error-background-color);
    }

    &.success {
        background-color: var(--toast-success-background-color);
    }

    &.info {
        background-color: var(--toast-info-background-color);
    }

    &.warn {
        background-color: var(--toast-warning-background-color);
    }
}
