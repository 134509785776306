:root {
    --table-row-hover-background: linear-gradient(
        90deg,
        transparent 0%,
        color-mix(in hsl, var(--grey-400), transparent 73%) 32%,
        transparent 100%
    );

    --table-cell-border-color: var(--grey-200);
}

html.dark {
    --table-row-hover-background: linear-gradient(
        90deg,
        transparent 0%,
        color-mix(in hsl, var(--grey-600), transparent 73%) 32%,
        transparent 100%
    );

    --table-cell-border-color: var(--grey-400);
}
