$segmentBorder: 1px solid color-mix(in hsl, var(--grey-400), transparent 46%);

.ui.steps {
    border: none;
    box-shadow: var(--segment-box-shadow);

    border-radius: 7px;
    margin-bottom: 0.5em;
    border-radius: $large-border-radius;
    .step.completed {
        i.checkmark,
        .icon:before {
            color: $primary;
        }
    }
    .step.active {
        background: var(--white);
        i.checkmark {
            color: color-mix(in hsl, var(--grey-500), transparent 50%);
        }
        &:after {
            background: var(--white);
            border-color: color-mix(in hsl, var(--grey-500), transparent 50%);
        }
        .title {
            color: $header-font-color;
        }
    }
    .step.disabled {
        background: var(--primary-100);
        &:after {
            background: var(--primary-100);
        }
    }
    .step {
        i.checkmark {
            color: color-mix(in hsl, var(--grey-500), transparent 50%);
        }
        border-right-color: color-mix(in hsl, var(--grey-500), transparent 50%);
        &:after {
            background: var(--white);
            border-color: color-mix(in hsl, var(--grey-500), transparent 50%);
        }
    }
}
