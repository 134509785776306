// This value may need to be adjusted if it becomes apparent it is blocked by elements.
// It is currently set as low as possible to ensure it still is behind modals, overlays, menus etc.
$mobile-float-buttons-zindex: 2;

$loading-dimmer-zindex: 1000;

$mobile-menu-dimmer-zindex: 1005;

// Layout
$sidebar-zindex: 1500;
$topbar-zindex: $sidebar-zindex + 1;
$topbar-mobile-zindex: $mobile-menu-dimmer-zindex - 1;

// Modals
$modal-dimmer-zindex: 2000; // If updating this value, also update overlay.component.ts
$modal-zindex: $modal-dimmer-zindex + 1;
$tooltip-zindex: $modal-zindex + 1;

$env-bar-zindex: 50;
$zendesk-widget-zindex: $env-bar-zindex + 1;
$setup-guide-button-zindex: $env-bar-zindex + 1;

$table-popup-zIndex: 100;
