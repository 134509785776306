[data-tooltip][data-inverted]:before {
    background: var(--grey-800);
}

[data-tooltip][data-inverted]:after {
    background: var(--grey-800);
    color: var(--white);
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    white-space: pre;
    display: block;
}
