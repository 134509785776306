@import 'variables';
@import 'grid';

/**
 * Semantic UI Customizations
 */
@import 'elements/ui.dimmer';
@import 'elements/ui.sortable';
@import 'elements/ui.calendar';
@import 'elements/ui.loader';
@import 'elements/ui.modal';
@import 'elements/ui.accordion';
@import 'elements/ui.checkbox';
@import 'elements/ui.card'; //Outside of segment for nav card
@import 'elements/ui.divider';
@import 'elements/ui.items'; //Outside of segment for nav card
@import 'elements/ui.segments';
@import 'elements/ui.grid';
@import 'elements/ui.steps';
@import 'elements/ui.dropdown';
@import 'elements/ui.label';
@import 'elements/ui.donut';

/** One offs */
.ui.segment,
p i {
    padding-right: 14px;
}
.ui.sticky {
    z-index: 10;
}
.ui.avatar {
    background: var(--white);
}
.ui.form .inline.field .ui.dropdown input.search:hover {
    border: none !important;
}
.ui.form .inline.field > label {
    color: var(--grey-900);
}

.ui.basic.button,
.ui.basic.active.button,
.ui.basic.buttons .button {
    color: var(--grey-700) !important;
    &:hover {
        color: var(--grey-800) !important;
        background-color: var(--card-background-color) !important;
    }
}

.ui.menu {
    background: var(--card-background-color);
    .item.disabled {
        color: var(--grey-500) !important;
    }

    .dropdown.item .menu {
        background: var(--card-background-color);
    }

    .ui.dropdown .menu > .item {
        color: var(--grey-900) !important;

        &.active {
            color: var(--dropdown-item-selected-font-color) !important;
        }
    }
}

/**
     * Elements needed outside of the main app space
     */
app-topbar,
.outlet.container {
    @import 'elements/ui.button';
}

.ui.segment {
    @import 'elements/ui.segment';
    @import 'elements/ui.header';
    @import 'elements/ui.button';
    @import 'elements/ui.badge';
    @import 'elements/ui.checkbox';
    @import 'elements/ui.progress';
    @import 'elements/ui.message';
    @import 'elements/ui.upload';
    @import 'elements/ui.statistics';
    @import 'elements/ui.table.scss';
    @import 'elements/ui.rating';
    @import 'elements/ui.form';
    @import 'elements/ui.search-filter';
    @import 'elements/ui.menu';
    @import 'elements/ui.list';
    @import 'elements/ui.comments';
    @import 'elements/ui.popup';
}
