:root {
    /* Buttons and fields should match border radius for consistency*/
    --button-border-radius: var(--field-border-radius);
    --button-default-border-color: transparent;
    --button-default-border-width: 1px;
    --button-default-border-image: none;
    --button-box-shadow: inset 0 1px #ffffff1f, 0 1px 2px #0a0d120d;

    --button-focus-outline-thickness: 2px;
    --button-focus-outline-offset: 2px;
    --button-default-focus-outline-color: var(--primary-600);

    --button-primary-border-color: var(--primary-600);
    --button-primary-background-image: linear-gradient(#ffffff14, #fff0); /* Provides a 3D effect on primary buttons*/

    --button-link-hover-background-color: var(--grey-200);
    --button-link-font-color: var(--primary-600);

    --button-danger-border-color: var(--error-600);
    --button-danger-background-color: var(--error-600);
    --button-danger-background-image: var(
        --button-primary-background-image
    ); /* Re-uses primary button's background gradient*/
    --button-danger-hover-background-color: var(--error-700);
    --button-danger-font-color: var(--white);
    --button-danger-focus-outline-color: var(--error-600);

    --button-primary-disabled-background-color: var(--grey-200);
    --button-primary-disabled-border-color: var(--grey-400);
    --button-primary-disabled-font-color: var(--grey-600);

    --button-outline-border-color: var(--primary-300);
    --button-outline-disabled-background-color: var(--button-primary-disabled-background-color);
    --button-outline-disabled-border-color: var(--button-primary-disabled-border-color);
    --button-outline-disabled-font-color: var(--button-primary-disabled-font-color);
}

html.dark {
    --button-link-hover-background-color: var(--grey-100);
    --button-link-font-color: var(--primary-700);

    --button-primary-background-image: linear-gradient(#f1f1f10f, #ffffff00); /* Reverses gradient in dark mode*/
    --button-primary-disabled-background-color: var(--grey-100);
    --button-primary-disabled-border-color: var(--grey-600);
    --button-primary-disabled-font-color: var(--grey-700);

    --button-outline-border-color: var(--primary-600);

    --button-danger-border-color: var(--error-400);
    --button-danger-background-color: var(--error-400);
    --button-danger-hover-background-color: var(--error-500);
}
