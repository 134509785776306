@import 'z-index';

/**
 * Modals
 */
body.animating.in.dimmable,
body.dimmed.dimmable {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.ui.dimmer {
    z-index: $modal-dimmer-zindex;
    background-color: var(--dialog-overlay-background-color);
    &.no-dimmer {
        background-color: inherit;
    }
    &.inverted {
        z-index: $loading-dimmer-zindex;
        background-color: color-mix(in hsl, var(--card-background-color), transparent 15%);
    }
}

.ui.modals.dimmer {
    z-index: $modal-dimmer-zindex;
    background: var(--dialog-overlay-background-color) !important;
}
