@import './typography/fonts.css';

:root {
    --font-family: 'Tenon';
    --heading-level-1-font-weight: 500;
    --heading-level-2-font-weight: 500;
    --heading-level-3-font-weight: 500;
    --heading-level-4-font-weight: 500;
    --heading-level-5-font-weight: 500;
    --heading-level-6-font-weight: 500;

    --body-font-size: 0.875rem; /* 14px */

    font-size: 16px;
}

html.humi-rebrand {
    --font-family: 'PPMori';
    --heading-level-1-font-weight: 600;
    --heading-level-2-font-weight: 600;
    --heading-level-3-font-weight: 600;
    --heading-level-4-font-weight: 600;
    --heading-level-5-font-weight: 600;
    --heading-level-6-font-weight: 600;
}
