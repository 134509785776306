@import 'platform-colors';
@import 'typography';
@import 'variables';

.mat-button-toggle-group {
    &.split-button-group {
        .mat-button-toggle {
            .mat-button-toggle-label-content {
                line-height: $button-line-height;
                display: flex;
                align-items: center;
                gap: calc-spacing(2);
                padding: 0;
            }
        }
    }
}
