@import 'variables';
@import 'z-index';
@import 'typography';

.ui.modal {
    &.outlet {
        box-shadow: var(--segment-box-shadow);
        padding: 24px;
        border-radius: 7px;
        background-color: var(--card-background-color);
        color: $primary-font;
        z-index: $modal-zindex;
        left: 50% !important;
        &.scrolling {
            transform: perspective(1px) translate(-50%, 0%) !important;
            top: -150px;
        }
        &:not(.scrolling) {
            position: fixed;
            top: 40% !important;
            transform: perspective(1px) translate(-50%, -50%) !important;
        }
        h2.header {
            font-size: map-get($header-sizes, 2);
            color: $header-font-color;
            font-family: $font-family;
            font-weight: $font-bold;
            border-bottom: none;
        }
        .content {
            padding-top: 0;
            background-color: transparent;
        }
        .actions {
            padding: 1em;
            background: transparent;
            border-top: none;
            text-align: right;
            .ui.button {
                min-width: 0;
            }
        }
    }
}
