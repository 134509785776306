@import 'variables';
@import 'utils/spacing';

$segmentMargin: 0px 0px 25px 0px;
$segmentFontColor: $neutral-darker;
$segmentPadding: 25px 40px;

&:not(.basic) {
    .ui.segment:not(.basic) {
        border: 1px solid $neutral-light;
        box-shadow: none;
    }
}

& {
    background: var(--card-background-color);
    color: $segmentFontColor;
    clear: both;
    box-shadow: var(--segment-box-shadow);
    padding: $segmentPadding;
    border: $segment-border;
    border-radius: $segment-border-radius;
    margin: $segmentMargin;

    &.empty {
        text-align: center;
    }

    &.table {
        padding: $segment-padding;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        overflow: visible;

        .ui.text.menu {
            padding-right: 40px;
        }

        app-segment-header {
            padding-left: 40px;
            padding-right: 40px;

            @media (max-width: $small-breakpoint) {
                padding: calc-spacing(3);
            }
        }

        @media (max-width: $small-breakpoint) {
            padding-top: 0;
        }

        .ui.table {
            thead,
            tbody,
            tfoot {
                tr {
                    td:first-of-type,
                    th:first-of-type {
                        padding-left: 50px;
                    }

                    td:last-of-type,
                    th:last-of-type {
                        padding-right: 50px;
                    }
                }
            }
        }
    }

    &.grid {
        margin: $segmentMargin !important;
        box-shadow: var(--segment-box-shadow);
        border: $segment-border;
        border-radius: $segment-border-radius;
    }

    &.basic {
        padding: 54px;
        padding-top: 46px;
        padding-bottom: 100px;
        margin-top: 0;

        &.narrow {
            max-width: 800px;
            margin: 0 auto;
        }
    }

    &.collapsible {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        border: none;
    }

    &.widget {
        height: calc(100% - 23px - 14px); //minus h3 header minus seg margin top
        display: flex;
        justify-content: center;
        align-items: center;

        .ui.statistics {
            width: 100%;
        }
    }

    &.attached.segment {
        border: 1px solid var(--grey-400);
    }

    &.secondary {
        background: color-mix(in hsl, var(--black), transparent 98%);
        padding: 1em;

        &:not(.borderless) {
            border: 1px dashed color-mix(in hsl, var(--black), transparent 80%);
        }

        &.borderless {
            border: none;
        }
    }

    &.compact {
        padding: 9px 15px;
    }

    &.tertiary {
        background-color: color-mix(in hsl, var(--black), transparent 96%);
        border-radius: $border-radius !important;

        .ui.header {
            color: $primary-font;
        }
    }

    &.raised {
        border: none;
        padding: 1em;

        &.inverted {
            &.blue {
                background-color: $primary-light !important;
            }
        }
    }

    /**
     * Segment Edit Link
     */
    & > a.edit {
        margin-top: 11px;
        font-size: 13px;
        float: right;
        color: $primary;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        &.basic {
            overflow: hidden;
        }
    }
}
