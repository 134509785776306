@import 'utils/spacing';

.dialog.sticky-content {
    padding: 0 calc-spacing(3);

    .dialog-header.sticky-header,
    ui-dialog-footer.sticky-buttons {
        position: sticky;
        background: var(--card-background-color);
        z-index: 100;
        padding: calc-spacing(3) 0;
        margin: 0;

        @media (max-width: $medium-breakpoint) {
            padding: calc-spacing(2) 0;
        }
    }

    .dialog-header.sticky-header {
        top: 0;
    }

    ui-dialog-footer.sticky-buttons {
        bottom: 0;
    }
}
