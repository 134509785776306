@import 'platform-colors';

/**
 * UI Checkbox
 */
.ui.segment .ui.form .field .ui.checkbox label,
.ui.checkbox label,
.ui.form .field .ui.checkbox label {
    line-height: 20px !important;
    &:after,
    &:before {
        line-height: 18px;
    }
}
.ui.toggle.checkbox .box,
.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
    min-height: 1.6rem;
}
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
    background-color: $primary !important;
}

.ui.toggle.checkbox input:not(:checked) ~ .box:before,
.ui.toggle.checkbox input:not(:checked) ~ label:before {
    background-color: $neutral-light !important;
}

// UI toggle circle
.ui.toggle.checkbox input ~ label:after {
    width: 1.25rem;
    height: 1.25rem;
    top: 2px;
    box-shadow: none;
    background-color: var(--white) !important;
}

.ui.toggle.checkbox input:not(:checked) ~ label:after {
    left: 3px;
}

.ui.checkbox label,
.ui.checkbox + label {
    color: $neutral-darkest;
    padding-left: 4.5em;
    margin-bottom: 0.5em;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
    color: $primary !important;
}

.ui.radio.checkbox input:checked ~ label {
    &:after {
        background-color: var(--radio-button-selected-inner-circle-color) !important;
    }
    &:before {
        background-color: var(--radio-button-default-background-color);
        border: 2px solid var(--radio-button-selected-outer-circle-color);
    }
}

.ui.radio.checkbox label:before {
    background-color: var(--radio-button-default-background-color);
    border: 2px solid var(--radio-button-default-outer-circle-color);
}
