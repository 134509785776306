/**
 * UI Comments
 */
.ui.comments {
    max-width: none;
    .comment {
        border-left: 3px solid var(--grey-400);
        padding-left: 1.5em;
        &:first-child {
            margin: 0 0 0;
        }
        &:not(:first-child) {
            margin: 1.5em 0 0;
        }
        &.positive {
            border-left-color: var(--success-500);
        }
        &.negative {
            border-left-color: var(--highlight-600);
        }
    }
    .comment .avatar img,
    .ui.comments .comment img.avatar {
        border-radius: 50%;
    }
    .comment .actions {
        color: var(--grey-500);
    }
    .comment .text,
    .comment .author {
        color: var(--grey-800);
    }
    .comment .metadata {
        color: var(--grey-500);
        float: right;
    }
}
