@import 'platform-colors';

/* Color Picker */
.sp-container {
    border-radius: 0;
    background-color: transparent;
    border: none;
    padding: 0;
}

.sp-color,
.sp-hue,
.sp-clear {
    border: 1px solid $neutral-light;
}

.sp-button-container {
    display: none;
}
