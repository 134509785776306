@import '../platform-colors';

$rules: ('border-color');

$mappedRules: (
    text-color: 'color',
    bg-color: 'background-color',
);

@each $key, $color in $platform-color-map {
    @each $rule in $rules {
        .#{$rule}-#{'' + $key} {
            #{$rule}: $color;
        }
    }
    @each $alias, $rule in $mappedRules {
        .#{$alias}-#{'' + $key} {
            #{$rule}: $color;
        }
    }
}
