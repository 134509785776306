@import 'platform-colors';
@import 'typography';
@import 'utils/spacing';

$border-radius: 10px;

// Mobile Breakpoint
$mobile-breakpoint: 900px;

// Colors
$background: var(--page-background-color);

$header-height: 60px; // Old, deprecated
$topbar-height: 64px;

// This value is the combined height of our topbar (static) and our special information bar (dynamic)
$combined-top-bar-heights: calc(#{$topbar-height} + var(--special-information-bar-height));

$header-icon-outline-color: $neutral;

// Sidebar
$sidebar-mobile-width: 320px;
$sidebar-desktop-width: 170px;

// Right sidebar
$right-desktop-sidebar-width: 350px;

$logo-height: 32px;

// Headers
$header-margin-bottom: 0.2em;

// Segment
$segment-border: none;
$segment-border-radius: 10px;
$segment-padding: 25px;
$primary-segment-width: 0px;
$primary-segment-padding: 60px;
$primary-segment-padding-bottom: 60px;

// Border Color
$inactive-border-color: color-mix(in hsl, $neutral-light, transparent 50%);
// Border Radius
$large-border-radius: $border-radius;
$small-border-radius: $border-radius;

// Input borders
$input-border-radius: 3px;
$input-max-width: 600px;

$input-border-color-light: $neutral-light;
$input-border-color: $neutral-light;
$input-border-color-hover: $neutral;
$input-border-color-active: $black;
$input-border-color-error: $error;

// Timing
$transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);

// Layout
$page-vertical-padding: calc-spacing(5);
$page-horizontal-padding: calc-spacing(5);
$mobile-vertical-padding: calc-spacing(3);
$mobile-horizontal-padding: calc-spacing(3);
$page-gap: calc-spacing(3);
$mobile-gap: calc-spacing(3);
$profile-gap: calc-spacing(4);
$profile-mobile-gap: calc-spacing(2);
$max-content-width: 1440px;

// Buttons
$button-vertical-padding: calc-spacing(1);
$button-horizontal-padding: calc-spacing(2);
$button-line-height: 20px;
$mat-icon-height: 20px;

// Tables
$mobile-table-max-height: 500px; // For tables that aren't meant to extend the full height of the page they should be capped on mobile to reduce the need to endlessly scroll
$desktop-table-padding: calc-spacing(4);

$inset-box-shadow: inset 0px 0px 4px color-mix(in hsl, var(--black), transparent 75%);
$card-box-shadow:
    0px 7px 14px color-mix(in hsl, var(--grey-900), transparent 92%),
    0px 3px 6px color-mix(in hsl, var(--black), transparent 95%);
$dialog-box-shadow: 0px 3px 10px color-mix(in hsl, var(--grey-900), transparent 85%);

$shadows: (
    inset-box-shadow: $inset-box-shadow,
    card-box-shadow: $card-box-shadow,
    dialog-box-shadow: $dialog-box-shadow,
);
