:host::ng-deep .handsontable th:first-child {
    text-align: left;
}

.hot-container {
    overflow: hidden;
    width: 100%;
    // height is set in component based on number of rows
}

hot-table .handsontable.htRowHeaders.htColumnHeaders {
    // Fix tabbar appearing below HOT
    z-index: 0;
}

.handsontable th {
    background-color: var(--grey-300);
}

.handsontable td {
    background-color: var(--card-background-color);
}

.handsontable tbody th.ht__highlight,
.handsontable thead th.ht__highlight {
    background-color: var(--grey-400);
}

.handsontable .htDimmed {
    color: var(--field-default-placeholder-font-color);
}
