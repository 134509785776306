@import 'platform-colors';

/**
 * Variables
 */

$fieldBorder: 1px solid var(--grey-500);
$fieldBorderRadius: 3px;
$fieldBackground: color-mix(in hsl, var(--grey-400), transparent 90%);
$fieldMarginBottom: 28px;
$fieldFontColor: var(--grey-900);

$staticFieldFontColor: var(--grey-900);

$fieldActiveBorderColor: $primary;
$fieldActiveFontColor: $neutral-darkest;

$labelColor: $neutral-darkest;
$labelMarginBottom: 16px;

$fieldsFieldMargin: 36px;

/**
 * Styles
 */
.ui.form {
    text-align: left;

    .required.field > label:after {
        color: $neutral-darkest;
    }

    input[type='search'],
    input[type='text'],
    input[type='number'] {
        background: var(--card-background-color);
        color: var(--grey-900);
        border-color: var(--grey-900);
    }

    &.static {
        .inline.field {
            color: $staticFieldFontColor;
            margin-bottom: 20px;
            label {
                line-height: 1;
                padding-top: 0;

                &::after {
                    content: var(--localized-colon, ':');
                    display: inline-block;
                }
            }
        }
    }
    .fields {
        margin: 0;
    }
    .field.error {
        label {
            color: var(--error) !important;
        }
        input {
            border-color: var(--error) !important;
        }
    }
    .field {
        color: $fieldFontColor;
        &:not(.inline) {
            margin-bottom: $fieldMarginBottom;
            label {
                margin-bottom: $labelMarginBottom;
                color: $labelColor;
            }
        }
        &.inline {
            .ui.checkbox {
                // To emulate other inputs
                width: 100%;
                max-width: 500px;
                min-width: 250px;

                padding-top: 0.25em;
                padding-bottom: 0.25em;
                label {
                    padding-top: 0;
                }
            }
            .ui:not(.checkbox) {
                label {
                    padding-top: 0.67857143em;
                }
            }
            label {
                min-width: 250px;
                padding-top: 0.67857143em;
            }
            p {
                vertical-align: top;
            }
            textarea,
            input:not([type]),
            input[type='date'],
            input[type='datetime-local'],
            input[type='email'],
            input[type='file'],
            input[type='number'],
            input[type='password'],
            input[type='search'],
            input[type='tel'],
            input[type='text'],
            input[type='time'],
            input[type='url'],
            .ui.action.input {
                max-width: 500px;
            }
            .errors {
                padding-left: calc(250px + 0.85714286em);
                margin-top: 0.5em;
                margin-left: 0.5em;
            }
        }

        label {
            font-size: $font-size;
            font-weight: $font-weight;
            color: $neutral-darkest;
            margin-bottom: $labelMarginBottom;
        }

        p {
            width: 100%;
            max-width: 500px;
            font-size: $font-size;
            font-weight: $font-weight;
            color: var(--grey-900);
            padding: 2px 1em;
        }

        textarea,
        input:not([type]),
        input[type='date'],
        input[type='datetime-local'],
        input[type='email'],
        input[type='file'],
        input[type='number'],
        input[type='password'],
        input[type='search'],
        input[type='tel'],
        input[type='text'],
        input[type='time'],
        input[type='url'] {
            background: $fieldBackground;
            border: $fieldBorder;
            border-radius: $fieldBorderRadius;
            width: 100%;
            font-size: $font-size;
            font-weight: $font-weight;
            color: $fieldFontColor;

            &:focus {
                border-color: $fieldActiveBorderColor;
                color: $fieldActiveFontColor;
            }

            &::placeholder,
            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder {
                color: $neutral-light;
                font-weight: normal;
                font-size: 13px;
            }
        }

        &.error,
        &.invalid {
            label {
                color: $neutral-darkest !important;
            }
            input,
            .ui.dropdown {
                border-color: $input-border-color-error !important;
                border-right-width: 8px;
            }
        }

        .ui.action.input {
            .ui.button {
                min-width: 0;
            }
            width: 100%;
        }

        .ui.selection.dropdown,
        .ui.dropdown {
            background: $fieldBackground;
            border: $fieldBorder;
            border-radius: $fieldBorderRadius;
            width: 100%;
            &:not(.fluid) {
                max-width: 500px;
            }
            font-size: $font-size;
            font-weight: $font-weight;
            color: var(--grey-900);
            &:hover {
                border-color: $input-border-color-hover;
            }

            &.active {
                border-color: $input-border-color-active;
                .menu {
                    border-color: $input-border-color-active;
                    .item {
                        font-size: $font-size;
                        font-weight: $font-weight;
                        color: var(--grey-900);
                    }
                }
            }
        }
    }

    .fields {
        .field:not(.inline):not(:last-child) {
            margin-right: $fieldsFieldMargin;
        }
    }

    textarea {
        max-width: 1000px;
    }
}

.ui.form .inline.field > :first-child,
.ui.form .inline.fields .field > :first-child {
    margin-right: 0;
}

/**
 * Size Variations
 */
.ui.form {
    &.small {
        .field {
            font-size: $font-size;
            label {
                font-size: calc(#{$font-size} - 1px);
            }
            margin-bottom: 20px;
        }
        .field {
            &:not(.inline) {
                label {
                    margin-bottom: 9px;
                }
            }
        }
    }
}

[deprecated-ui-dropdown] {
    z-index: 2;
}
