.ui.divider {
    border-color: color-mix(in hsl, var(--black), transparent 97%);
    border-bottom: none;
    color: var(--grey-900);
    &.clear,
    &.hidden {
        display: none; // Hide these for now while we work out margin on new design
    }
    &.double {
        margin: 2rem 0;
        display: none; // Hide these for now while we work out margin on new design
    }
}
