@use 'ag-grid-community/styles' as ag;
@import 'libs/styles';
@import 'utils/styles';
@import 'utils/sizes';
@import 'layout';
@import 'deprecated/styles';
@import 'material/theming';
@import 'basic-tags';
@import 'elements/index';
@import 'classes';
@import 'shadows';
@import 'logos';
@include ag.grid-styles(
    (
        themes: (
            material: (),
            // Used by payroll tables
            quartz:
                (
                    borders: solid 0.5px,
                    border-color: var(--grey-200),
                    header-background-color: var(--grey-200),
                    header-foreground-color: $neutral-darker,
                    header-column-resize-handle-display: none,
                    data-color: $neutral-darkest,
                    row-hover-color: $primary-max-lightest,
                    cell-horizontal-border: solid var(--grey-200),
                    range-selection-border-color: $primary,
                    range-selection-highlight-color: $primary-lightest,
                    background-color: var(--card-background-color),
                    secondary-foreground-color: var(--grey-900),
                    popup-shadow: var(--dropdown-box-shadow),
                ),
        ),
    )
);

.ag-theme-quartz {
    --ag-wrapper-border-radius: 0;
    --ag-icon-font-color: var(--grey-900);
    --ag-foreground-color: var(--grey-900);
    --ag-checkbox-checked-color: var(--primary-600);

    .ag-header {
        border-bottom: solid 2px $neutral;
    }

    html.dark & {
        --ag-header-background-color: var(--grey-300);
        --ag-border-color: var(--grey-300);
    }
}

.ag-theme-quartz.dark {
    --ag-border-color: var(--grey-300); /* Border color */
    --ag-header-background-color: var(--grey-300);
    --ag-cell-horizontal-border: solid var(--grey-300);
}

.ag-theme-material {
    --ag-background-color: var(--card-background-color);
    --ag-foreground-color: var(--grey-900);
    --ag-border-color: var(--grey-300);
    --ag-header-background-color: var(--card-background-color);
    --ag-header-cell-hover-background-color: var(--grey-100);
    --ag-subheader-background-color: var(--grey-200);
    --ag-row-hover-color: var(--grey-100);
    --ag-control-panel-background-color: var(--grey-200);
    --ag-material-accent-color: var(--primary-600);
    --ag-material-primary-color: var(--primary-600);
    --ag-range-selection-border-color: var(--primary-600);
    --ag-popup-shadow: var(--dropdown-box-shadow);

    input::placeholder {
        color: var(--field-default-placeholder-font-color) !important;
    }

    .ag-checkbox-input-wrapper.ag-checked::before {
        background-color: var(--white);
        border-radius: 4px;
    }
}

html {
    --localized-colon: ':';

    &[lang='fr'] {
        --localized-colon: ' :';
    }
}
