@use 'sass:math';
@import '../variables';

span.counter {
    padding: 0 calc-spacing(2);
    background-color: $primary;
    color: $white;
    border-radius: 10px;
    font-weight: $font-medium;

    @media (max-width: $small-breakpoint) {
        padding: 0 calc(0.5 * $font-size);
    }
}
