:root {
    --radio-button-size: 16px;
    --radio-button-label-gap: 0.5rem;
    /* This should always match radio-button-size in symmetry (ie. if size is an even number this needs to be even number, if one's odd they both must be odd.
    Otherwise there will be an obvious 1px offset with centering.*/
    --radio-button-line-height: calc(var(--radio-button-size) + 4px);

    --radio-button-default-outer-circle-color: var(--grey-600);
    --radio-button-default-background-color: var(--card-background-color);

    --radio-button-selected-inner-circle-color: var(--primary-600);
    --radio-button-selected-outer-circle-color: var(--radio-button-selected-inner-circle-color);
    --radio-button-selected-inner-circle-width: 5px;

    --radio-button-focus-outline-thickness: 2px;
    --radio-button-focus-outline-offset: 2px;

    --radio-button-errored-inner-circle-color: var(--error-700);
    --radio-button-errored-outer-circle-color: var(--radio-button-errored-inner-circle-color);

    --radio-button-disabled-inner-circle-color: var(--grey-500);
    --radio-button-disabled-outer-circle-color: var(--radio-button-disabled-inner-circle-color);
    --radio-button-disabled-background-color: var(--grey-200);
    --radio-button-disabled-font-color: var(--field-disabled-font-color);
}

html.dark {
    --radio-button-selected-inner-circle-color: var(--primary-500);
    --radio-button-errored-inner-circle-color: var(--error-400);
}
