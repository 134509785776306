@import 'variables';

/** Breakpoint Utilities */
.hide-small,
.mobile /** Legacy class, to be removed */ {
    @media (max-width: $small-breakpoint) {
        display: none !important;
    }
}

.only-small {
    @media (min-width: $inclusive-small-breakpoint) {
        display: none !important;
    }
}

.hide-large {
    @media (min-width: $large-breakpoint) {
        display: none !important;
    }
}

.only-medium {
    @media (max-width: $medium-breakpoint) {
        display: none !important;
    }
}

.hide-medium {
    @media (min-width: $inclusive-medium-breakpoint) {
        display: none !important;
    }
}

.only-large {
    @media (max-width: $inclusive-large-breakpoint) {
        display: none !important;
    }
}

div.flex {
    display: flex;
}

.capitalize {
    text-transform: capitalize !important;
}

.centered:not(.page-container) {
    text-align: center;
}

.align-left {
    text-align: left;
}

p.capitalize {
    text-transform: capitalize !important;
}

p.notset {
    color: var(--field-disabled-font-color) !important;
}

a.disabled {
    opacity: 0.5;
    pointer-events: none;
}
p.empty {
    padding: 1.28em 0em;
}

.right.floated {
    float: right;
}

.uppercase {
    text-transform: uppercase !important;
}

.cell-blur a,
p.blur,
span.blur,
div.blur {
    text-shadow: 0px 0px 12px var(--grey-700);
    color: transparent !important;
    transition: 0.3s;
    &:hover {
        text-shadow: none;
        color: inherit !important;
    }
}

.lettercase {
    text-transform: lowercase !important;
    &:first-letter {
        text-transform: uppercase !important;
    }
}

.truncate-text {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}

.no-wrap {
    white-space: nowrap !important;
}

.fa-margin-right {
    margin-right: 1em;
}
.fa-margin-left {
    margin-left: 1em;
}

/**
 * These are deprecated. Avoid using them please.
 */
.flex {
    &.start {
        justify-content: start;
    }
    &.center {
        justify-content: center;
    }
    &.end {
        justify-content: end;
    }
    &.flex-end {
        justify-content: flex-end;
    }
    &.space-around {
        justify-content: space-around;
    }
    &.space-between {
        justify-content: space-between;
    }
    &.middle {
        align-items: center;
    }
    &.align-flex-end {
        align-items: flex-end;
    }
}

.ui.segment.basic app-header {
    margin-bottom: 1rem;
}

@media only screen and (max-width: $mobile-breakpoint) {
    ::ng-deep {
        .desktop {
            display: none !important;
        }

        .ui.header {
            float: none;
            width: 100%;
        }
        .ui.segment .ui.right.floated.button {
            float: none;
            min-width: 0;
            width: 100% !important;
            margin-bottom: 1em;
        }
        .ui.segment.basic {
            padding: 75px 1em 75px 1em !important;
        }
    }
}
