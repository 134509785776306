@import 'platform-colors';

.mat-progress-bar-fill::after {
    background-color: $primary;
}

.mat-progress-bar-buffer {
    background: $neutral-light;
}

.green-fill {
    .mat-progress-bar-fill::after {
        background-color: $success;
    }
}
