.checkmark-mat-select mat-pseudo-checkbox.mat-pseudo-checkbox {
    display: none;
}

.mat-pseudo-checkbox {
    color: var(--checkbox-default-border-color);
}

.checkmark-mat-select.mat-mdc-option-multiple.mat-mdc-selected {
    &:after {
        content: ' ';
        background: url('/assets/material-override-icons/check-success-darkest.svg') center no-repeat;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
}
