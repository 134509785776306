@import 'legacy-utilities';
@import 'vars';
@import 'spacing';
@import 'flex';
@import 'color-utils';

.font-bold {
    font-weight: $font-bold !important;
}

.font-semibold {
    font-weight: $font-semibold !important;
}

.font-medium {
    font-weight: $font-medium !important;
}

.font-light {
    font-weight: $font-light !important;
}

.nowrap {
    white-space: nowrap !important;
}
