@use 'sass:string';

// This file contains definitions for all width and height related utility classes.

// Represents the percentage of the container, ie. 25% width or 100% height
$dimension-percentages: 25, 50, 75, 100;

// Mixins used to easily repeat style definitions for the below classes
@mixin width($percent) {
    width: string.unquote('#{$percent}%');

    // .row comes from .responsive-grid (see _layout.scss)
    // If we are limiting the width of a row then we need to add half of the column gap in order to keep horizontal alignment
    &.row {
        @if $percent == 100 {
            padding-right: 0;
        } @else {
            padding-right: calc(var(--responsive-grid-desktop-col-gap, #{calc-spacing(3)}) / 2);

            @media (max-width: $medium-breakpoint) {
                padding-right: calc(var(--responsive-grid-mobile-col-gap, #{calc-spacing(3)}) / 2);
            }
        }
    }
}

@mixin height($percent) {
    height: string.unquote('#{$percent}%');
}

/*  Basic dimensions (w-25, w-50, w-75, w-100, h-25, h-50, h-75, h-100)

These dimensions are not restricted by breakpoints, meaning they will fill whatever gap is left by the breakpoint restricted dimension classes below.
ie. class="w-100 lg-w-50" means
 - Screens below lg = 100% width
 - Screens lg and up = 50% width
*/
@each $size in $dimension-percentages {
    .w-#{$size} {
        @include width($size);
    }
    .h-#{$size} {
        @include height($size);
    }
}

/*  Small dimensions (sm-w-25, sm-w-50, sm-w-75, sm-w-100, sm-h-25, sm-h-50, sm-h-75, sm-h-100)

These dimensions act upon the small-breakpoint and up. They overwrite the basic dimensions above, but are overwritten by medium dimensions and larger below.
ie. class="w-100 sm-w-75 md-w-50" means
 - Screens below sm = 100% width
 - Screens above sm and below md = 75% width
 - Screens above md = 50% width
*/
@each $size in $dimension-percentages {
    .sm {
        @media (min-width: $small-breakpoint) {
            &#{unquote('-w-#{$size}')} {
                @include width($size);
            }
            &#{unquote('-h-#{$size}')} {
                @include height($size);
            }
        }
    }
}

/*  Medium dimensions (md-w-25, md-w-50, md-w-75, md-w-100, md-h-25, md-h-50, md-h-75, md-h-100)

These dimensions act upon the medium-breakpoint and up. They overwrite the basic and small dimensions above, but are overwritten by large dimensions and larger below.
ie. class="w-100 md-w-75 lg-w-50" means
 - Screens below md = 100% width
 - Screens above md and below lg = 75% width
 - Screens above lg = 50% width
*/
@each $size in $dimension-percentages {
    .md {
        @media (min-width: $medium-breakpoint) {
            &#{unquote('-w-#{$size}')} {
                @include width($size);
            }
            &#{unquote('-h-#{$size}')} {
                @include height($size);
            }
        }
    }
}

/*  Large dimensions (lg-w-25, lg-w-50, lg-w-75, lg-w-100, lg-h-25, lg-h-50, lg-h-75, lg-h-100)

These dimensions act upon the large-breakpoint and up. They overwrite the basic, small and medium dimensions above, but are overwritten by xl dimensions and larger below.
ie. class="w-100 lg-w-75 xl-w-50" means
 - Screens below lg = 100% width
 - Screens above lg and below xl = 75% width
 - Screens above xl = 50% width
*/
@each $size in $dimension-percentages {
    .lg {
        @media (min-width: $large-breakpoint) {
            &#{unquote('-w-#{$size}')} {
                @include width($size);
            }
            &#{unquote('-h-#{$size}')} {
                @include height($size);
            }
        }
    }
}

/*  XL dimensions (xl-w-25, xl-w-50, xl-w-75, xl-w-100, xl-h-25, xl-h-50, xl-h-75, xl-h-100)

These dimensions act upon the xl-breakpoint and up. They overwrite the basic, small, medium and large dimensions above, but are overwritten by xxl dimensions.
ie. class="w-100 xl-w-75 xxl-w-50" means
 - Screens below xl = 100% width
 - Screens above xl and below xxl = 75% width
 - Screens above xxl = 50% width
*/
@each $size in $dimension-percentages {
    .xl {
        @media (min-width: $xl-breakpoint) {
            &#{unquote('-w-#{$size}')} {
                @include width($size);
            }
            &#{unquote('-h-#{$size}')} {
                @include height($size);
            }
        }
    }
}

/*  XXL dimensions (xxl-w-25, xxl-w-50, xxl-w-75, xxl-w-100, xxl-h-25, xxl-h-50, xxl-h-75, xxl-h-100)

These dimensions act upon the xxl-breakpoint and up. They overwrite the basic, small, medium. large and xl dimensions above.
ie. class="w-100 xxl-w-50" means
 - Screens below xxl = 100% width
 - Screens xxl and up = 50% width
*/
@each $size in $dimension-percentages {
    .xxl {
        @media (min-width: $xxl-breakpoint) {
            &#{unquote('-w-#{$size}')} {
                @include width($size);
            }
            &#{unquote('-h-#{$size}')} {
                @include height($size);
            }
        }
    }
}
/*
 allows for setting a static width with a css variable, but
 always remains responsive to the container it's in

 ex: if someone uses a large value for --static-width and then views the element on a small screen like on mobile)
*/
.static-width {
    width: 100%;
    max-width: var(--static-width, 230px);
}

.mw-unset {
    max-width: unset !important;
}
