@import 'platform-colors';
@import 'typography';

/**
 * UI Headers
 */

span,
div,
p {
    text-rendering: optimizeLegibility;
    font-weight: $font-weight;
}

.ui.header.literal-casing {
    text-transform: none !important;
}

@for $i from 1 through 6 {
    h#{$i} {
        .sub.header {
            font-weight: map-get($header-weights, $i);
        }
    }
}

h1.ui.header {
    font-size: map-get($header-sizes, 1);
    color: $header-font-color;
    font-family: $font-family;
    font-weight: map-get($header-weights, 1);
    line-height: map-get($header-line-heights, 1);
    margin-bottom: 30px;

    .sub.header {
        font-size: 19px;
        margin-top: 0.24em;
        line-height: 2em;
    }
}

h2.ui.header {
    font-size: map-get($header-sizes, 2);
    color: $header-font-color;
    font-family: $font-family;
    font-weight: map-get($header-weights, 2);
    line-height: map-get($header-line-heights, 2);
    margin-top: 35px;
    margin-bottom: 25px;

    .sub.header {
        font-size: 15px;
        line-height: 2em;
    }
}

h3.ui.header {
    font-size: map-get($header-sizes, 3);
    color: $header-font-color;
    font-family: $font-family;
    font-weight: map-get($header-weights, 3);
    line-height: map-get($header-line-heights, 3);

    .sub.header {
        line-height: 2em;
    }
}

h4.ui.header {
    font-size: $font-size;
    color: $neutral-darker;
    font-family: $font-family;
    font-weight: map-get($header-weights, 4);
    line-height: 1;
}

.ui.segment:not(.basic) {
    h1.ui.header,
    h2.ui.header,
    h3.ui.header,
    h4.ui.header {
        margin-top: 11px;
    }
}

.ui.header {
    font-family: $font-family;
    font-weight: map-get($header-weights, 1);
    color: $neutral-darker;

    margin: 0;

    &.marginless {
        margin: 0;
    }

    &.dividing {
        padding-bottom: 0.2em;
        margin-bottom: 20px;

        &:not(:first-of-type) {
            margin-top: 60px;
        }
    }

    &.left.floating {
        float: left;
    }

    &.ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .sub.header {
        font-weight: 300;
        color: $neutral-darkest;
    }

    &.left.floated.header {
        .back {
            padding-right: 0.5em;
            color: var(--grey-500);
            cursor: pointer;
            font-size: 19px;
        }
    }

    &.right.floated.step {
        font-size: 19px;
        color: var(--grey-500);

        span {
            padding-right: 0.5em;
        }

        i {
            color: color-mix(in hsl, var(--black), transparent 92%);
        }

        i .fa-circle.active {
            color: $primary;
        }
    }
}
