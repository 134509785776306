$alignments: center, baseline;
$justifications: center, space-around, space-between, flex-end;
$wrapping: wrap, wrap-reverse, nowrap;

$mappedAlignments: (
    start: flex-start,
    end: flex-end,
);

@mixin createAlignmentRules($alignment, $rule: $alignment) {
    $r: '.align-items-#{$alignment}';
    $s: '.align-self-#{$alignment}';
    $j: '.justify-#{$alignment}';

    #{unquote($r)} {
        align-items: $rule;
    }
    #{unquote($s)} {
        align-self: $rule;
    }
    #{unquote($j)} {
        justify-content: $rule;
    }
}

/**
 * This builds the utility classes for common flex layouts.
 */
@each $alignment in $alignments {
    @include createAlignmentRules($alignment);
}
@each $key, $value in $mappedAlignments {
    @include createAlignmentRules($key, $value);
}

.flex {
    display: flex;

    /* Create flex spacings
     eg: class="flex flex-1" -> { display: flex; flex: 1; }
         class="flex 4_5" -> { flex: 4.5 }
    */
    @for $i from 0 to 5 {
        & > .flex-#{$i} {
            flex: $i;
        }
        & > .flex-#{$i}_5 {
            flex: unquote('#{$i}.5');
        }
    }

    &.row {
        flex-direction: row;
    }
    &.row-reverse {
        flex-direction: row-reverse;
    }
    &.col {
        flex-direction: column;
    }
    &.col-reverse {
        flex-direction: column-reverse;
    }

    @each $justification in $justifications {
        &.justify-#{$justification} {
            justify-content: $justification;
        }
    }

    @each $type in $wrapping {
        &.#{type} {
            flex-wrap: $type;
        }
    }
}
