div .mat-icon.auto-size {
    width: auto;
    height: auto;
}

/**
 * Only to add specifity to the properties without using important
 */
p,
a,
div,
input,
span,
button {
    & .mat-icon {
        $size: #{$line-height}em;
        width: $size;
        height: $size;
        vertical-align: sub;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

/**
 * Need to account for the fact that our header tags don't have a line-height of 1.
 * The icons don't align nicely without this.
 */
@for $i from 1 through 6 {
    h#{$i} {
        & .mat-icon {
            $size: map-get($header-line-heights, $i);
            width: #{$size}em;
            height: #{$size}em;
        }
    }
}

mat-icon svg path[stroke-miterlimit] {
    stroke: currentColor;
}

.icon {
    &.private {
        color: $primary;
    }

    &.public {
        color: $highlight-lighter;
    }
}
