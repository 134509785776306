@import 'platform-colors';
@import 'responsive';
@import 'typography';

mat-checkbox.mat-mdc-checkbox.mat-primary {
    display: flex;

    // Unselected state
    --mdc-checkbox-unselected-icon-color: var(--checkbox-default-border-color);

    // Selected state
    --mdc-checkbox-selected-icon-color: var(--checkbox-selected-background-color);
    --mdc-checkbox-selected-checkmark-color: var(--checkbox-selected-check-color);

    // Hover state (same as non-hover)
    --mdc-checkbox-selected-hover-icon-color: var(--checkbox-selected-hover-background-color);
    --mdc-checkbox-selected-hover-checkmark-color: var(--checkbox-selected-hover-check-color);
    --mdc-checkbox-unselected-hover-icon-color: var(--checkbox-default-border-color);
    --mdc-checkbox-unselected-hover-state-layer-opacity: 0;

    // Focus state (same as non-focus)
    --mdc-checkbox-selected-focus-icon-color: var(--checkbox-selected-hover-background-color);
    --mdc-checkbox-selected-focus-checkmark-color: var(--checkbox-selected-hover-check-color);
    --mdc-checkbox-unselected-focus-icon-color: var(--checkbox-default-border-color);
    --mdc-checkbox-unselected-focus-state-layer-opacity: 0;

    // Disabled state
    --mdc-checkbox-disabled-unselected-icon-color: var(--checkbox-disabled-background-color);
    --mdc-checkbox-disabled-selected-icon-color: var(--checkbox-disabled-background-color);
    --mdc-checkbox-disabled-selected-checkmark-color: var(--checkbox-disabled-check-color);

    &.mat-mdc-checkbox-disabled {
        .mdc-checkbox__background {
            border-color: var(--checkbox-disabled-check-color) !important;
        }

        label {
            color: var(--checkbox-disabled-font-color);
        }
    }

    &.label-top .mdc-checkbox {
        align-self: flex-start;
        margin-top: calc((($line-height * $font-size) - var(--checkbox-size)) / 2);
    }

    // Checkbox dimensions ( W x H )
    --mdc-checkbox-state-layer-size: var(--checkbox-size);

    .mdc-checkbox {
        width: var(--checkbox-size);
        height: var(--checkbox-size);
        flex: 0 0 var(--checkbox-size);
        background-color: var(--card-background-color);
        border-radius: var(--checkbox-border-radius);

        .mdc-checkbox__background {
            top: 0;
            left: 0;
            width: var(--checkbox-size);
            height: var(--checkbox-size);
            border-radius: var(--checkbox-border-radius);
            border-width: var(--checkbox-border-width);
        }

        input:focus ~ .mdc-checkbox__background {
            outline: var(--checkbox-focus-outline-thickness) solid var(--checkbox-selected-background-color);
            outline-offset: var(--checkbox-focus-outline-offset);
        }
    }

    // Checkmark width
    .mdc-checkbox__checkmark-path {
        stroke-width: var(--checkbox-check-width);
    }

    .mdc-form-field > label {
        padding-left: var(--checkbox-label-gap);

        line-height: var(--checkbox-line-height);
    }

    @media (max-width: $small-breakpoint) {
        --checkbox-size: 14px;
    }
}

form.ng-submitted mat-checkbox.mat-mdc-checkbox.mat-primary {
    // Error state
    &.error,
    &.ng-invalid {
        --checkbox-selected-background-color: var(--checkbox-errored-background-color);
        --checkbox-selected-check-color: var(--checkbox-errored-check-color);
        --checkbox-unselected-background-color: var(--checkbox-errored-background-color);
        --checkbox-unselected-check-color: var(--checkbox-errored-check-color);
        --checkbox-selected-hover-background-color: var(--checkbox-errored-background-color);
        --checkbox-selected-hover-check-color: var(--checkbox-errored-check-color);

        .mdc-checkbox__background {
            // Angular material always wants a transparent border except for the default unselected state
            // Therefore we need to add an !important here (or use an incredibly verbose selector that would likely be brittle to updates)
            border-color: var(--checkbox-errored-check-color) !important;
        }

        .mdc-checkbox {
            background-color: var(--checkbox-errored-background-color);
        }
    }
}
