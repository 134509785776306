@import 'platform-colors';

/**
         * UI Dropdowns
         */

.ui.table .ui.icon.dropdown {
    border-radius: 4px;
    text-align: center;
    min-width: 40px;
    position: relative;
    top: -5px;
    min-height: 30px;
    margin-bottom: -1em;
    &:hover {
        i.icon {
            color: var(--grey-700);
        }
    }
    i.icon {
        vertical-align: bottom;
        color: var(--grey-500);
        margin: 0;
    }

    .menu {
        border: none;
        border-radius: 4px;
        background-color: var(--dropdown-background-color);
        box-shadow: var(--dropdown-box-shadow);
        .item {
            font-size: 14px !important;
            padding: 1em 2em !important;
            color: $primary-font !important;
            i {
                margin-right: 1em;
                opacity: 0.8;
                color: $secondary-font;
                font-size: 1em !important;
                position: relative;
            }
            &:hover {
                background: var(--grey-100) !important;
            }
        }
    }
}

.ui.icon.button.dropdown .menu {
    border: none;
    box-shadow: var(--dropdown-box-shadow);
    border-radius: var(--dropdown-border-radius) !important;
    padding: var(--dropdown-padding);
    margin-left: 0.5em;
    .item {
        padding: var(--dropdown-item-padding) !important;
        color: $primary-font !important;
        width: 100%;
        text-align: left;
        font-size: 14px !important;
        i {
            margin-right: 1em;
            opacity: 0.8;
            color: $secondary-font;
            font-size: 1em !important;
            position: relative;
        }

        &:hover {
            background: var(--dropdown-item-hover-background-color) !important;
            color: var(--dropdown-item-hover-font-color) !important;
        }
    }
}

.ui.dropdown .menu > .item.left .dropdown.icon {
    float: left;
    margin-left: 0;
}

.ui.dropdown .menu > .item.right .dropdown.icon {
    order: 1;
    margin-right: 0 !important;
    margin-left: auto;
}

.ui.dropdown .menu > .divider {
    margin: 0;
}

.ui.dropdown .menu {
    background-color: var(--dropdown-background-color);
    box-shadow: var(--dropdown-box-shadow);

    > .item {
        display: flex;
        align-items: center;
        color: var(--grey-900);
        font-size: var(--body-font-size);

        &:hover {
            background-color: var(--dropdown-item-hover-background-color);
            color: var(--dropdown-item-hover-font-color);
        }
    }
}
