/**
* UI Search & Filter
*/

.ui.search.input {
    .icon {
        color: $neutral-light;
    }
    input {
        width: 377px;
        border: 1px solid;
        border-color: $input-border-color-light;
        border-radius: $input-border-radius;
        font-size: $font-size;
        font-weight: $font-weight;
        color: $black;
        &:hover {
            border-color: $input-border-color-hover;
        }

        &:focus {
            border-color: $input-border-color-active;
        }

        &::placeholder,
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
            color: $neutral-light;
        }
    }
}

.ui.small.search {
    font-weight: 300;
    font-family: $font-family;
    transition: 0.3s;
    border-radius: $small-border-radius;

    padding: 0;
    font-size: 13px;
    height: 25px;
    line-height: 27px;
    min-width: 70px;

    padding: 0 10px;

    box-shadow: 0 0 0 0 color-mix(in hsl, var(--grey-900), transparent 85%) inset;
    color: var(--white);
    text-shadow: none;
    background-image: none;

    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: var(--grey-400) none;
    margin: 0 0.25em 0 0;
    text-transform: none;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &.white {
        border: 1px solid var(--grey-400);
        background-color: var(--white);
        input {
            color: var(--grey-500);
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: var(--grey-500);
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                color: var(--grey-500);
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                color: var(--grey-500);
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                color: var(--grey-500);
            }
        }
        &:hover {
            border: 1px solid color-mix(in hsl, var(--black), transparent 60%) !important;
        }
    }
    .ui.transparent.icon.input {
        height: 100%;
        font-family: $font-family;
        font-weight: 300;
        font-size: 13px;
        input {
            color: var(--white);
            font-family: $font-family;
            font-weight: 300;
            font-size: 15px;
            line-height: 26px;
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: color-mix(in hsl, var(--white), transparent 20%);
                font-family: $font-family;
                font-weight: 300;
                font-size: 13px;
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                color: color-mix(in hsl, var(--white), transparent 20%);
                font-family: $font-family;
                font-weight: 300;
                font-size: 13px;
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                color: color-mix(in hsl, var(--white), transparent 20%);
                font-family: $font-family;
                font-weight: 300;
                font-size: 13px;
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                color: color-mix(in hsl, var(--white), transparent 20%);
                font-family: $font-family;
                font-weight: 300;
                font-size: 13px;
            }
        }
        .icon {
            color: var(--white);
        }
    }

    &.right.floated.small {
        top: -3px;
        position: relative;
        margin-left: 0.5em;
        float: right;
        margin-right: 0;
    }
}

.ui.segment > .ui.small.search {
    margin-top: -4px;
}

i.icon.search::before {
    color: var(--grey-900);
}
