@import 'constants';
@import 'variables';
@import 'platform-colors';
@import 'utils/spacing';

mat-form-field {
    .mdc-text-field--outlined {
        --mdc-outlined-text-field-outline-width: 1px;
        --mdc-outlined-text-field-focus-outline-width: 1px;
        --mdc-outlined-text-field-label-text-tracking: normal;
        --mdc-outlined-text-field-container-shape: var(--field-border-radius);
        --mdc-outlined-text-field-outline-color: var(--field-border-color, var(--field-default-border-color));
        --mdc-outlined-text-field-input-text-color: var(--field-default-input-font-color);

        // We always use the same font color for the label in every state
        --mdc-outlined-text-field-label-text-color: var(--field-label-font-color);
        --mdc-outlined-text-field-error-label-text-color: var(--field-label-font-color);
        --mdc-outlined-text-field-focus-label-text-color: var(--field-label-font-color);
        --mdc-outlined-text-field-disabled-label-text-color: var(--field-label-font-color);
        --mdc-outlined-text-field-error-focus-label-text-color: var(--field-label-font-color);

        // Hover state
        --mdc-outlined-text-field-hover-outline-color: var(--field-default-border-color);

        // Focus state
        --mdc-outlined-text-field-focus-outline-color: var(--field-focused-border-color);

        // Disabled state
        --mdc-outlined-text-field-disabled-input-text-color: var(--field-disabled-font-color);
        --mdc-outlined-text-field-disabled-outline-color: var(--field-disabled-border-color);
    }

    // Can be added to a field if it has no visual label
    &.no-labels {
        --field-label-line-height: 0px;
    }

    // Can be added to a field if it has no hints or errors
    &.no-helpers {
        --field-subscript-gap: 0px;
        --field-bottom-spacing: 0px;
        --field-subscript-line-height: 0px;

        .mat-mdc-form-field-subscript-wrapper {
            height: 0;
        }
    }

    &.mat-mdc-form-field.mat-primary {
        --mat-mdc-form-field-floating-label-scale: 1;
        --mat-form-field-outlined-label-text-populated-size: var(--field-label-font-size);
        --mat-form-field-container-text-tracking: normal;
        --mat-form-field-subscript-text-tracking: normal;

        padding-top: var(--field-label-line-height); // Make room for floating label

        .mat-mdc-form-field-infix {
            min-height: var(--field-min-height);
            padding: var(--field-vertical-padding) 0;
            font-size: var(--field-input-font-size);
            line-height: var(--field-input-line-height);

            // Unfortunately some legacy Semantic CSS occasionally adds styles that we need to cancel out here
            input {
                padding: 0;
                font-size: unset;
                line-height: unset;
            }
        }

        // Autofill styling on the entire component, not just input
        &:has(input:-webkit-autofill, select:-webkit-autofill) {
            --field-background-color: var(--field-autofill-background-color);
        }

        .mat-mdc-form-field-flex {
            position: relative;
            padding: 0 var(--field-horizontal-padding);
        }

        // Label positioning and styling
        // This accounts for the fact that Angular Material tries to shrink the label and place it in an awkward position
        .mat-mdc-floating-label.mdc-floating-label {
            overflow: visible;
            line-height: var(--field-label-line-height);
            left: 0;

            // We need to add the translateX transform here. Unfortunately we don't want to overwrite the existing translateY provided by Angular Material
            // That's why there's a weird calc happening here, it's pulled directly from the existing value of this variable coming from Angular Material. If you inspect and find something different, feel free to update this!
            --mat-mdc-form-field-label-transform: translateY(
                    calc((var(--field-label-font-size) + var(--field-label-vertical-gap) + 34.75px) * -1)
                )
                translateX(calc(var(--field-horizontal-padding) * -1));

            // Required marker color should not ever update
            .mat-mdc-form-field-required-marker::after {
                color: var(--field-label-font-color);
            }
        }

        .mdc-text-field--outlined {
            padding: 0;
        }

        // Restores a border around the entire field. By default Angular Material tries to add a notch in the form where there is no border
        .mdc-notched-outline {
            z-index: 0;
            background-color: var(--field-background-color, var(--field-default-background-color));
            border-radius: var(--field-border-radius);
            transition: background-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);

            .mdc-notched-outline__notch {
                clip-path: none;
                border-top-style: solid;
                border-top-width: 1px;
                border-left-width: 0 !important;
                margin-left: 0;
            }
        }

        // Placeholder styling
        .mat-mdc-input-element::placeholder {
            color: var(--field-default-placeholder-font-color);
        }

        // Disable ripple effect
        .mat-mdc-button-persistent-ripple {
            display: none;
        }

        // Suffix icons are anything added to the end of the form such as a date-picker launcher button or a "$" or "%" icon
        .mat-mdc-form-field-icon-suffix {
            white-space: nowrap;

            > mat-icon {
                height: var(--field-min-height);
                padding: 0;
            }

            .mat-datepicker-toggle .mat-mdc-button-base {
                display: flex;
                justify-content: flex-end;
                height: var(--field-min-height);

                mat-icon {
                    position: absolute;
                    top: calc((var(--field-min-height) - var(--field-suffix-icon-size)) / 2);
                    width: var(--field-suffix-icon-size);
                    height: var(--field-suffix-icon-size);
                }
            }
        }

        &.mat-form-field-disabled {
            --field-background-color: var(--field-disabled-background-color);
        }

        // Subscript styling (hints and errors)
        .mat-mdc-form-field-subscript-wrapper {
            display: flex;
            margin-top: var(--field-subscript-gap);
            margin-bottom: var(--field-bottom-spacing);
            line-height: var(--field-subscript-line-height);

            .mat-mdc-form-field-hint-wrapper,
            .mat-mdc-form-field-error-wrapper {
                position: static;
                padding: 0;
                width: 100%;
            }
        }
    }
}

mat-hint.mat-mdc-form-field-hint,
mat-error.mat-mdc-form-field-error {
    font-size: var(--field-subscript-font-size);
    line-height: var(--field-subscript-line-height);
    color: var(--field-subscript-font-color, var(--field-default-subscript-font-color));
}

// Removes spacer added by Angular material that can cause alignment issues when no hint is present
mat-hint.mat-mdc-form-field-hint::before {
    display: none;
}

// Adds an error symbol in front of the text in a mat-error when a field is invalid
mat-error.mat-mdc-form-field-error {
    --field-subscript-font-color: var(--field-errored-subscript-font-color);

    // Adds a symbol to all mat-error subscripts in front of the text. Only if the errors have text content
    &:not(:empty)::before {
        content: '';
        position: relative;
        background-color: var(--field-errored-subscript-font-color);
        mask: url('/assets/shape-icons/Icon_Bold_Warning.svg') no-repeat 50% 50%;
        display: inline-block;
        width: var(--field-subscript-font-size);
        height: var(--field-subscript-font-size);
        top: calc(calc(var(--field-subscript-line-height) - var(--field-subscript-font-size)) / 2);
        margin-right: 0.25rem;
        flex-shrink: 0;
        align-self: flex-start;
    }
}

/**
.humi-warning is a class that can be added to a form field to make use of the hint component to display a warning.
This is currently only used for kyc-kyb revalidation warnings
*/
.mat-mdc-form-field.humi-warning,
ui-date-picker.humi-warning > .mat-form-field {
    &:not(.mat-form-field-invalid) {
        --field-subscript-font-color: var(--warning-900);
        --field-background-color: var(--warning-200);
        --field-border-color: var(--warning-900);

        .mat-mdc-form-field-hint::before {
            content: '';
            position: relative;
            background-color: var(--warning-900);
            mask: url('/assets/shape-icons/Icon_Bold_Warning.svg') no-repeat 50% 50%;
            display: inline-block;
            margin-right: 0.5rem;
            width: var(--field-subscript-font-size);
            height: var(--field-subscript-font-size);
            top: calc(calc(var(--field-subscript-line-height) - var(--field-subscript-font-size)) / 2);
        }
    }
}
