.cdk-overlay-pane div.mat-mdc-autocomplete-panel {
    --select-item-active-background-color: var(--dropdown-item-hover-background-color);
    --select-item-active-font-color: var(--dropdown-item-hover-font-color);

    /*
    Used anywhere that an autocomplete is anchored directly to the input and not to the form-field.
    This causes the positioning to appear narrower than the form field due to the input actually being smaller than the field.

    This only really occurs in auto-completes that are attached to an input and not necessarily a form-field.
    This occurs in the country and state autocomplete components for example because they're not attached to form-fields
    */
    &.input-anchored {
        position: absolute;
        width: calc(100% + (var(--field-horizontal-padding) * 2));
        left: calc(var(--field-horizontal-padding) * -1);
    }

    margin-top: calc(var(--select-panel-vertical-gap) + var(--field-vertical-padding));
    border-radius: var(--dropdown-border-radius) !important;
    box-shadow: var(--dropdown-box-shadow);
    background-color: var(--dropdown-background-color);
    color: var(--grey-900);

    &:not([class*='mat-elevation-z']) {
        box-shadow: var(--dropdown-box-shadow);
    }

    .mat-mdc-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
        background-color: var(--dropdown-item-hover-background-color);
        color: var(--dropdown-item-hover-font-color);
    }
}
