:root {
    --tip-default-font-size: var(--body-font-size);
    --tip-default-line-height: 1.25rem;
    --tip-default-padding: 0.25rem;
    --tip-default-border-radius: 4px;
    --tip-default-font-color: var(--primary-700);
    --tip-default-background-color: var(--primary-200);
    --tip-default-icon-size: 16px;
}

html.dark {
    --tip-default-background-color: var(--primary-500);
    --tip-default-font-color: var(--primary-900);
}

/* Ensures links display as the same color as the rest of the tip text.
Otherwise they may end up with inconsistent coloring since the default link color is lighter */
humi-tip a {
    color: var(--tip-default-font-color);
}
