:root {
    --banner-default-padding: 0.875rem 1rem;

    --banner-warning-border-color: var(--warning-300);
    --banner-warning-background-color: var(--warning-200);
    --banner-warning-font-color: var(--warning-900);

    --banner-success-border-color: var(--success-300);
    --banner-success-background-color: var(--success-200);
    --banner-success-font-color: var(--success-800);

    --banner-info-border-color: var(--grey-300);
    --banner-info-background-color: var(--grey-100);
    --banner-info-font-color: var(--grey-900);

    --banner-error-border-color: var(--error-300);
    --banner-error-background-color: var(--error-200);
    --banner-error-font-color: var(--error-700);
}

html.dark {
    --banner-success-border-color: var(--success-400);
    --banner-success-background-color: var(--success-300);
    --banner-success-font-color: var(--success-900);

    --banner-info-border-color: var(--grey-500);

    --banner-error-background-color: var(--error-300);
    --banner-error-border-color: var(--error-400);
    --banner-error-font-color: var(--error-900);

    --banner-warning-font-color: var(--grey-100);
    --banner-warning-background-color: var(--warning-400);
}
