/**
 * UI Star Rating
 */
$star-color: var(--warning-600);
$star-border: var(--grey-400);

.ui.star.rating {
    .icon {
        color: var(--grey-400);
    }

    .active.icon {
        color: $star-color !important;
        text-shadow: none !important;
    }

    .icon.selected,
    .ui.star.rating .icon.selected.active {
        background: 0 0 !important;
        color: $star-color !important;
        text-shadow:
            0 -1px 0 $star-border,
            -1px 0 0 $star-border,
            0 1px 0 $star-border,
            1px 0 0 $star-border !important;
    }

    i {
        padding-right: 20px;
    }
}
