// Converting SCSS variables to css variables allows them to be used in css-in-js solutions
:root {
    @each $key, $val in $shadows {
        --#{$key}: #{$val};
    }
    --segment-box-shadow: 0 7px 14px 0 color-mix(in hsl, var(--grey-900), transparent 92%),
        0 3px 6px 0 color-mix(in hsl, var(--black), transparent 95%);
}

html.dark {
    @each $key, $val in $shadows {
        --#{$key}: none;
    }
    --segment-box-shadow: none;
}
