/* stylelint-disable color-no-hex */
.ui.badge {
    &.floated {
        &.left {
            float: left;
        }
        &.right {
            float: right;
        }
    }

    /* Ribbon */
    &.circle {
        margin: 1.8em;
        margin-bottom: 5.5em;
        background: #a1bd35;
        width: 76px;
        height: 76px;
        border-radius: 38px;
        position: relative;
        font-size: 11px;
        /*
        &.spinning {
            cursor:pointer;
            .fa {
                animation-name: rotate;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
        }
*/
        @keyframes rotate {
            0% {
                transform: translate(-50%, -50%) rotateY(0deg) skew(0, 0);
                opacity: 1;
            }
            25% {
                transform: translate(-50%, -50%) rotateY(90deg) skew(10deg, 10deg);
                opacity: 1;
            }
            26% {
                transform: translate(-50%, -50%) rotateY(91deg) skew(10deg, 10deg);
                opacity: 0.3;
            }

            50% {
                transform: translate(-50%, -50%) rotateY(180deg) skew(0, 0);
                opacity: 0.3;
            }

            74% {
                transform: translate(-50%, -50%) rotateY(269deg) skew(-10deg, -10deg);
                opacity: 0.3;
            }
            75% {
                transform: translate(-50%, -50%) rotateY(270deg) skew(-10deg, -10deg);
                opacity: 1;
            }
            100% {
                transform: translate(-50%, -50%) rotateY(360deg) skew(0, 0);
                opacity: 1;
            }
        }

        span {
            display: block;
            position: absolute;
            bottom: -16px;
            transform: translate(0%, 100%);
            width: 85px;
            margin-left: -0.5em;
            font-size: $font-size;
            text-align: center;
            color: #8d8d8d;
            font-weight: bold;
        }
        div {
            font-size: 13px;
            line-height: 1;
            position: absolute;
            font-weight: bold;
            background: #d9e6a5;
            /* width: 44px; */
            /* height: 34px; */
            padding: 4px;
            border-radius: 25%;
            background: #90a92f;
            text-align: center;
            /* line-height: 45px; */
            bottom: -8px;
            right: -10px;
        }
        .svg-inline--fa {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            font-size: 4em;
            transform: translate(-50%, -50%);
            color: color-mix(in hsl, var(--black), transparent 25%);

            transform-style: preserve-3d;
            transition-property: transform;
        }

        /*
        &.stacked {
            &:before {
                content:' ';
                width:100%;
                height:100%;
                border:50%;
                background:blue;
            }
        }
        */

        &.green {
            background: lighten(#a1bd35, 30);
            border: 3px solid darken(#a1bd35, 5);
            .svg-inline--fa {
                color: darken(#a1bd35, 5);
            }
            div {
                background: darken(#a1bd35, 5);
                color: lighten(#a1bd35, 40);
            }
        }
        &.blue {
            background: lighten(#4ba0ad, 30);
            border: 3px solid darken(#4ba0ad, 5);
            .svg-inline--fa {
                color: darken(#4ba0ad, 5);
            }
            div {
                background: darken(#4ba0ad, 5);
                color: lighten(#4ba0ad, 40);
            }
        }
        &.orange {
            background: lighten(#f37a38, 30);
            border: 3px solid darken(#f37a38, 5);
            .svg-inline--fa {
                color: darken(#f37a38, 5);
            }
            div {
                background: darken(#f37a38, 5);
                color: lighten(#f37a38, 40);
            }
        }
        &.yellow {
            background: lighten(#ffc335, 30);
            border: 3px solid darken(#ffc335, 5);
            .svg-inline--fa {
                color: darken(#ffc335, 5);
            }
            div {
                background: darken(#ffc335, 5);
                color: lighten(#ffc335, 40);
            }
        }
        &.purple {
            background: lighten(#89267d, 30);
            border: 3px solid darken(#89267d, 5);
            .svg-inline--fa {
                color: darken(#89267d, 5);
            }
            div {
                background: darken(#89267d, 5);
                color: lighten(#89267d, 40);
            }
        }
        &.pink {
            background: lighten(#b43253, 30);
            border: 3px solid darken(#b43253, 5);
            .svg-inline--fa {
                color: darken(#b43253, 5);
            }
            div {
                background: darken(#b43253, 5);
                color: lighten(#b43253, 40);
            }
        }
        &.red {
            background: lighten(#da5449, 30);
            border: 3px solid darken(#da5449, 5);
            .svg-inline--fa {
                color: darken(#da5449, 5);
            }
            div {
                background: darken(#da5449, 5);
                color: lighten(#da5449, 40);
            }
        }
    }
}
