/**
 * UI Progress Bar
 */

.ui.progress {
    .label {
        color: var(--grey-700);
        font-weight: normal;
    }
}

.ui.thin.progress {
    font-size: 5px;
    border-radius: 1rem;
    .bar {
        border-radius: 1rem;
    }
}

.ui.blue.progress .bar {
    background-color: $primary !important;
}

.ui.yelow.progress .bar {
    background-color: $warning !important;
}

.ui.green.progress .bar {
    background-color: $success !important;
}

.ui.success.progress .bar {
    background-color: $primary !important;
}

.ui.red.progress .bar {
    background-color: $error-light !important;
}
