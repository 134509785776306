:root {
    --black: hsl(0, 0%, 0%);
    --white: hsl(0, 0%, 100%);
    --base: hsl(40, 33%, 98%);

    --primary-100: hsl(180, 33%, 96%);
    --primary-200: hsl(187, 100%, 92%);
    --primary-300: hsl(187, 44%, 76%);
    --primary-400: hsl(187, 32%, 59%);
    --primary-500: hsl(187, 72%, 31%);
    --primary-600: hsl(187, 71%, 26%);

    --grey-100: hsl(270, 20%, 98%);
    --grey-200: hsl(240, 5%, 96%);
    --grey-300: hsl(240, 6%, 90%);
    --grey-400: hsl(240, 5%, 84%);
    --grey-500: hsl(240, 5%, 65%);
    --grey-600: hsl(240, 4%, 46%);
    --grey-700: hsl(240, 5%, 34%);
    --grey-800: hsl(240, 5%, 26%);
    --grey-900: hsl(240, 4%, 16%);

    --highlight-100: hsl(5, 73%, 97%);
    --highlight-200: hsl(10, 71%, 93%);
    --highlight-300: hsl(10, 76%, 85%);
    --highlight-400: hsl(10, 77%, 76%);
    --highlight-500: hsl(10, 82%, 68%);
    --highlight-600: hsl(22, 100%, 43%);
    --highlight-700: hsl(22, 100%, 37%);
    --highlight-800: hsl(22, 100%, 31%);
    --highlight-900: hsl(22, 100%, 22%);

    --success-100: hsl(150, 18%, 96%);
    --success-200: hsl(144, 20%, 90%);
    --success-300: hsl(147, 23%, 79%);
    --success-400: hsl(147, 26%, 66%);
    --success-500: hsl(148, 33%, 47%);
    --success-600: hsl(159, 100%, 27%);
    --success-700: hsl(159, 100%, 24%);
    --success-800: hsl(159, 100%, 19%);
    --success-900: hsl(159, 100%, 14%);

    --error-100: hsl(7, 53%, 97%);
    --error-200: hsl(0, 53%, 93%);
    --error-300: hsl(3, 56%, 84%);
    --error-400: hsl(2, 53%, 73%);
    --error-500: hsl(3, 50%, 58%);
    --error-600: hsl(7, 70%, 46%);
    --error-700: hsl(7, 71%, 40%);
    --error-800: hsl(6, 70%, 33%);
    --error-900: hsl(7, 71%, 23%);

    --warning-100: hsl(40, 86%, 97%);
    --warning-200: hsl(40, 82%, 94%);
    --warning-300: hsl(40, 57%, 81%);
    --warning-400: hsl(42, 63%, 74%);
    --warning-500: hsl(44, 53%, 58%);
    --warning-600: hsl(47, 77%, 49%);
    --warning-700: hsl(47, 80%, 43%);
    --warning-800: hsl(47, 80%, 35%);
    --warning-900: hsl(46, 80%, 25%);

    --informative-100: hsl(220, 86%, 97%);
    --informative-200: hsl(222, 81%, 94%);
    --informative-300: hsl(222, 82%, 87%);
    --informative-400: hsl(220, 84%, 78%);
    --informative-500: hsl(217, 86%, 67%);
    --informative-600: hsl(213, 87%, 57%);
    --informative-700: hsl(213, 66%, 46%);
    --informative-800: hsl(213, 66%, 38%);
    --informative-900: hsl(213, 66%, 27%);
}

html.humi-rebrand {
    --primary-100: hsl(280, 14%, 96%);
    --primary-200: hsl(274, 15%, 91%);
    --primary-300: hsl(278, 16%, 80%);
    --primary-400: hsl(278, 17%, 68%);
    --primary-500: hsl(280, 19%, 56%);
    --primary-600: hsl(280, 30%, 44%);
    --primary-700: hsl(280, 30%, 36%);
    --primary-800: hsl(280, 30%, 29%);
    --primary-900: hsl(279, 30%, 21%);
}

html.dark {
    --base: hsl(0, 0%, 11%);

    --primary-100: hsl(280, 30%, 14%);
    --primary-200: hsl(279, 30%, 21%);
    --primary-300: hsl(280, 30%, 29%);
    --primary-400: hsl(280, 30%, 36%);
    --primary-500: hsl(280, 30%, 44%);
    --primary-600: hsl(278, 20%, 54%);
    --primary-700: hsl(278, 17%, 68%);
    --primary-800: hsl(278, 16%, 80%);
    --primary-900: hsl(274, 15%, 91%);

    --grey-100: hsl(0, 0%, 15%);
    --grey-200: hsl(0, 0%, 20%);
    --grey-300: hsl(0, 0%, 25%);
    --grey-400: hsl(0, 0%, 33%);
    --grey-500: hsl(0, 0%, 44%);
    --grey-600: hsl(0, 0%, 56%);
    --grey-700: hsl(0, 0%, 70%);
    --grey-800: hsl(0, 0%, 82%);
    --grey-900: hsl(0, 0%, 100%);

    --highlight-100: hsl(22, 100%, 15%);
    --highlight-200: hsl(22, 100%, 22%);
    --highlight-300: hsl(22, 100%, 31%);
    --highlight-400: hsl(22, 100%, 37%);
    --highlight-500: hsl(22, 100%, 46%);
    --highlight-600: hsl(13, 80%, 63%);
    --highlight-700: hsl(10, 77%, 76%);
    --highlight-800: hsl(10, 76%, 85%);
    --highlight-900: hsl(10, 71%, 93%);

    --success-100: hsl(158, 100%, 9%);
    --success-200: hsl(159, 100%, 14%);
    --success-300: hsl(159, 100%, 19%);
    --success-400: hsl(159, 100%, 24%);
    --success-500: hsl(159, 100%, 29%);
    --success-600: hsl(150, 32%, 49%);
    --success-700: hsl(147, 26%, 66%);
    --success-800: hsl(147, 23%, 79%);
    --success-900: hsl(143, 22%, 93%);

    --error-100: hsl(7, 70%, 15%);
    --error-200: hsl(7, 71%, 23%);
    --error-300: hsl(6, 70%, 33%);
    --error-400: hsl(7, 71%, 40%);
    --error-500: hsl(6, 70%, 49%);
    --error-600: hsl(4, 63%, 61%);
    --error-700: hsl(3, 59%, 74%);
    --error-800: hsl(3, 56%, 84%);
    --error-900: hsl(0, 53%, 93%);

    --warning-100: hsl(47, 79%, 17%);
    --warning-200: hsl(46, 80%, 25%);
    --warning-300: hsl(47, 80%, 35%);
    --warning-400: hsl(47, 80%, 43%);
    --warning-500: hsl(47, 88%, 53%);
    --warning-600: hsl(44, 87%, 65%);
    --warning-700: hsl(41, 84%, 77%);
    --warning-800: hsl(40, 83%, 86%);
    --warning-900: hsl(40, 82%, 94%);

    --informative-100: hsl(212, 67%, 18%);
    --informative-200: hsl(213, 66%, 27%);
    --informative-300: hsl(213, 66%, 38%);
    --informative-400: hsl(213, 66%, 46%);
    --informative-500: hsl(213, 76%, 54%);
    --informative-600: hsl(217, 86%, 67%);
    --informative-700: hsl(220, 84%, 78%);
    --informative-800: hsl(222, 82%, 87%);
    --informative-900: hsl(222, 81%, 94%);
}
