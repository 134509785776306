:root {
    --dropdown-box-shadow: 0px 20px 24px -4px rgba(39, 39, 42, 0.08), 0px 8px 8px -4px rgba(39, 39, 42, 0.03),
        0px 3px 3px -1.5px rgba(39, 39, 42, 0.1);
    --dropdown-border-radius: 0.5rem;
    --dropdown-padding: 0.5rem 0;
    --dropdown-background-color: var(--card-background-color);

    --dropdown-item-hover-background-color: var(--grey-200);
    --dropdown-item-hover-font-color: var(--grey-900);
    --dropdown-item-selected-background-color: var(--primary-100);
    --dropdown-item-selected-font-color: var(--primary-600);
    --dropdown-item-horizontal-padding: 1rem;
    --dropdown-item-vertical-padding: 0.625rem;
    --dropdown-item-padding: var(--dropdown-item-vertical-padding) var(--dropdown-item-horizontal-padding);
}

html.dark {
    --dropdown-box-shadow: 0px 20px 24px -4px rgba(39, 39, 42, 0.08), 0px 8px 8px -4px rgba(39, 39, 42, 0.03),
        0px 3px 3px -1.5px rgba(39, 39, 42, 0.1);
    --dropdown-background-color: var(--grey-400);

    --dropdown-item-hover-font-color: var(--white);
    --dropdown-item-selected-font-color: var(--white);
}
