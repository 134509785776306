@import 'variables';
@import 'platform-colors';

/**
 * UI Card
 */

// Vars
$itemsItemHeaderSize: 14px;
$itemsItemHeaderColor: $black;
$itemsItemHeaderFontWeight: normal;

$itemsItemDescriptionSize: 13px;
$itemsItemDescriptionColor: $neutral-darkest;
$itemsItemDescriptionFontWeight: 300;

.ui.items .item {
    app-avatar {
        padding-right: 1em;
    }
    .content {
        .header:not(.ui) {
            font-size: $itemsItemHeaderSize;
            color: $itemsItemHeaderColor;
            font-weight: $itemsItemHeaderFontWeight;
        }
        .description {
            margin-top: 6px;
            font-size: $itemsItemDescriptionSize;
            color: $itemsItemDescriptionColor;
            font-weight: $itemsItemDescriptionFontWeight;
        }
    }
}
