:root {
    --field-min-height: 36px;
    --field-vertical-padding: 0.5rem;
    --field-horizontal-padding: 10px;
    --field-border-radius: 8px;
    --field-bottom-spacing: 10px;

    --field-label-font-size: var(--body-font-size);
    --field-label-line-height: 20px;
    --field-label-vertical-gap: 0.25rem;
    --field-label-font-color: var(--grey-700);

    --field-input-font-size: var(--body-font-size);
    --field-input-line-height: 20px;

    --field-subscript-font-size: 0.75rem;
    --field-subscript-line-height: 1rem;
    --field-subscript-gap: 0.25rem;

    --field-suffix-icon-size: 16px;

    --field-default-input-font-color: var(--grey-900);
    --field-default-background-color: var(--card-background-color);
    --field-default-border-color: var(--grey-400);
    --field-default-placeholder-font-color: var(--grey-600);
    --field-default-subscript-font-color: var(--field-label-font-color);

    --field-disabled-font-color: var(--grey-600);
    --field-disabled-background-color: var(--grey-100);
    --field-disabled-border-color: var(--field-default-border-color);

    --field-focused-border-color: var(--primary-600);

    --field-errored-background-color: var(--card-background-color);
    --field-errored-border-color: var(--error-600);
    --field-errored-subscript-font-color: var(--field-errored-border-color);

    /* 40% transparency for less vibrancy */
    --field-autofill-background-color: color-mix(in hsl, var(--informative-300), transparent 60%);
}

html.dark {
    --field-default-border-color: var(--grey-600);

    --field-disabled-font-color: var(--grey-700);
}
