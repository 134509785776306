/**
 * Variables
 */
$button-border-radius: 4px;
$button-font-weight: 500;

/**
 * Styles
 */
.ui.button:not(.circular) {
    font-weight: $font-medium;
    font-family: $font-family;
    font-size: 15px;
    transition: 0.3s;
    border-radius: $button-border-radius;
    box-shadow: none;
    background-color: var(--grey-400);
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $button-font-weight !important;
    &:not(.fluid):not(.basic):not(.collapsing) {
        min-width: 100px;
    }
    &.inverted {
        background: var(--white);
        border: none;
        color: var(neutral-500) !important;
        border-radius: 4px;
        &:hover {
            color: var(neutral-600) !important;
        }
    }
    &.collapsing {
        min-width: auto !important;
        min-height: auto !important;
        display: inline-flex;
    }
    &.basic {
        box-shadow: none;
        border: none;
        border-radius: 3px;
        background-color: var(--white);
        box-shadow: none;
        padding-top: 0;
        padding-bottom: 0;
        height: 35px;
        min-height: 35px;

        &:not(.icon) i {
            margin-right: 0.5em;
        }

        &:hover {
            box-shadow: none;
        }
        &:not(.active):active {
            z-index: 1;
        }
    }

    &:not(.active):active {
        opacity: 1;
    }

    &.small {
        padding: 0.1em 0.75em;
        font-size: $font-size;
        height: auto;
        line-height: 27px;
        min-height: none;
    }

    &.floated {
        width: auto !important;
    }
    &.large.dark-text {
        background-image: url(/images/arrow_right_black.png);
    }
    &.large.no-arrow {
        background-image: none !important;
    }

    &.right.floated.small {
        //top: -3px;
        position: relative;
        margin-left: 0.5em;
    }
}

.ui.button {
    &.primary {
        background-color: $primary;
        color: var(--white);
        &:hover {
            box-shadow:
                0 4px 8px 0 color-mix(in hsl, $primary, transparent 85%),
                0 1px 2px 0 color-mix(in hsl, var(--black), transparent 70%);
            background-color: $primary-light;
        }
        &:active {
            background: $primary-light;
        }
    }

    &.neutral {
        background-color: $neutral-darkest;
        color: var(--white);
        &:hover {
            box-shadow: 0 4px 8px 0 color-mix(in hsl, $neutral-darkest, transparent 85%) 0 1px 2px 0
                color-mix(in hsl, var(--black), transparent 70%);
            background-color: $neutral-dark;
        }
        &:active {
            background: $neutral-dark;
        }
    }
}

.ui.form .ui.button {
    &.white.scrolling.dropdown.small {
        color: var(--grey-500);
        border: 1px solid var(--grey-400) !important;
        background-color: var(--white);
        &:hover {
            border: 1px solid color-mix(in hsl, var(--black), transparent 60%) !important;
        }
        .dropdown.icon {
            color: var(--grey-500) !important;
        }
    }
}

.ui.buttons .button:first-child {
    border-left: none;
    margin-left: 0;
    border-top-left-radius: 0.28571429rem;
    border-bottom-left-radius: 0.28571429rem;
}

.ui.buttons .button {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin: 0;
    border-radius: 0;
    margin: 0;
}

.ui.buttons .button:last-child {
    border-top-right-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;
}

@media only screen and (max-width: $mobile-breakpoint) {
    .ui.basic.button {
        text-align: center;
    }
}
