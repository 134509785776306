@import 'variables';
@import 'mixins/loadable';
@import 'utils/spacing';

/**
 * Layout Styles
 */
/** Empty States */
.empty {
    img {
        display: block;
        margin: 1em auto 3em auto;
        max-height: 120px;
    }
}

/**
 * UI Layout
 */
.ui.layout {
    display: flex;
    .sidebar:first-child {
        z-index: 11; // fix for pages with tables
        width: 275px;
        min-width: 275px;
        margin-right: 15px;
    }
    .content:last-child {
        margin-left: 15px;
        flex-grow: 1;
    }
    .sidebar:last-child {
        width: 275px;
        min-width: 275px;
        margin-left: 15px;
    }
    .content:first-child {
        margin-right: 15px;
        flex-grow: 1;
    }

    @media (max-width: $medium-breakpoint) {
        flex-direction: column;

        .sidebar {
            width: unset !important;
            min-width: unset !important;
            margin: 0 !important;
        }
    }
}

.ui.basic.segment {
    @media (max-width: $medium-breakpoint) {
        padding: $mobile-vertical-padding $mobile-horizontal-padding !important;
    }
}

/**
 * Layout Utilities
 */
.no-margin {
    margin: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.pad-top {
    padding-top: 1em;
}

.clear {
    clear: both;
}

.pull-up {
    position: relative;
    top: -5px;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

div.scroll.container {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 1em 0 2em 0;
}

@media only screen and (max-width: $mobile-breakpoint) {
    .ui.dimmer {
        .content {
            position: fixed;
            max-height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}

/**
 * Remove breaking behaviours from semantic on mobile
 */
@media only screen and (max-width: $mobile-breakpoint) {
    body .ui.container {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.page-container {
    @include loadable;

    display: flex;
    flex-direction: column;
    padding: $page-vertical-padding $page-horizontal-padding;
    gap: $page-gap;
    min-height: calc(100svh - #{$combined-top-bar-heights});

    // Adds padding to child elements rather than parent
    &.inner-padding {
        padding: 0;
        gap: 0;
        > *:first-child {
            padding: $page-vertical-padding $page-horizontal-padding calc($page-gap / 2);
        }
        > *:last-child {
            padding: calc($page-gap / 2) $page-horizontal-padding $page-vertical-padding;
        }
        > *:not(:last-child, :first-child) {
            padding: calc($page-gap / 2) $page-horizontal-padding;
        }

        // When inner-padding is used on a page-container that contains a profile-layout it can be used to enforce
        // a strict width on elements. This is helpful on pages that have elements that are trying to expand beyond the bounds of
        // the regular screen width (ie. compensation history table on Job & Pay)
        .profile-layout {
            column-gap: 0;
            padding-right: 0;

            &-content {
                overflow: hidden;
                padding-left: $profile-gap;
                padding-right: $page-horizontal-padding;
                padding-bottom: $profile-gap;

                @media (max-width: $medium-breakpoint) {
                    overflow: visible;
                    padding: 0;
                }
            }
        }

        @media (max-width: $medium-breakpoint) {
            > *:first-child {
                padding: $mobile-vertical-padding $mobile-horizontal-padding calc($mobile-gap / 2);
            }
            > *:last-child {
                padding: calc($mobile-gap / 2) $mobile-horizontal-padding $mobile-vertical-padding;
            }
            > *:not(:last-child, :first-child) {
                padding: calc($mobile-gap / 2) $mobile-horizontal-padding;
            }
        }
    }

    // Ensures content fits within screen height without scrolling
    &.no-scroll {
        max-height: calc(100svh - #{$combined-top-bar-heights});
    }

    // Centers content to half-width on desktop and full-width on mobile
    &.centered {
        align-items: center;
        > * {
            width: max(50%, calc($large-breakpoint - $sidebar-desktop-width));

            @media (max-width: $large-breakpoint) {
                width: 100%;
            }
        }

        &-both-axes {
            @extend .centered;
            justify-content: center;
        }
    }

    &.loading {
        &::before,
        &::after {
            position: fixed;
        }
    }

    // basic mobile styling
    @media (max-width: $medium-breakpoint) {
        height: calc(100svh - #{$combined-top-bar-heights});

        row-gap: $mobile-gap;
        padding: $mobile-vertical-padding $mobile-horizontal-padding;
        overflow: auto;
    }
}

.profile-layout {
    $sidebar-width: var(--profile-sidebar-width, 272px);

    display: grid;
    grid-template-areas: 'sidebar content';
    grid-template-columns: $sidebar-width 1fr;
    gap: $profile-gap;

    %column {
        display: flex;
        flex-direction: column;
        gap: $profile-gap;

        @media (max-width: $large-breakpoint) {
            gap: $profile-mobile-gap;
        }
    }

    &.reverse {
        grid-template-areas: 'content sidebar';
        grid-template-columns: 1fr $sidebar-width;
    }

    &-sidebar {
        grid-area: sidebar;
        @extend %column;
    }

    &-content {
        grid-area: content;
        @extend %column;
    }

    @media (max-width: $large-breakpoint) {
        display: flex;
        flex-direction: column;
        gap: $profile-mobile-gap;

        &.reverse {
            flex-direction: column-reverse;
        }
    }
}

// Used to display info on a profile page such as personal, job & pay, etc.
ui-card.profile-card {
    display: grid;
    grid-template-columns: auto 1fr;
    row-gap: calc-spacing(4);
    column-gap: calc-spacing(6);

    label + * {
        word-break: break-word;
    }

    app-data-field-group-values > div.long-text {
        white-space: pre-line;
    }

    @media (max-width: $medium-breakpoint) {
        gap: calc-spacing(2);

        label {
            font-weight: $font-medium;
            color: $secondary-font;
        }
    }
}

.profile-card-header {
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;

    h2 {
        margin: 0;
        margin-right: auto;
    }
}

// Used for pages that have content that should not expand infinitely.
// This is the ideal future state for all pages.
.max-width {
    max-width: $max-content-width;
    margin-right: auto;
    margin-left: auto;
}

/*  The .responsive-grid class is meant to assist with making rows of responsive content.
It responds automatically to screen width changes to re-order elements to fit on the screen. ie.
<div class="responsive-grid">
 <div class="row">
  <div> Item 1 </div>   <!-- Each item gets 1/3 width -->
  <div> Item 2 </div>
  <div> Item 3 </div>
 </div>

  <div class="row">
  <div> Item 1 </div>   <!-- Each item gets 1/2 width -->
  <div> Item 2 </div>
 </div>
</div>
*/
.responsive-grid {
    // The following CSS variables allow for customization of the grid for whatever use-case

    // --responsive-grid-min-col-width = the width at which items in the grid will no longer shrink and will instead wrap to the next line. Defaults to 240px
    $minimum-column-width: var(--responsive-grid-min-col-width, 240px);

    // --responsive-grid-max-col-width = the width at which items in the grid will no longer grow. Defaults to 1fr (no max, meaning they will grow infinitely)
    $maximum-column-width: var(--responsive-grid-max-col-width, 1fr);

    // --responsive-grid-desktop-col-gap = the space between columns in a row, defaults to 24px on desktop and 16px on mobile
    $desktop-column-gap: var(--responsive-grid-desktop-col-gap, #{calc-spacing(3)});
    $mobile-column-gap: var(--responsive-grid-mobile-col-gap, #{calc-spacing(2)});

    // --responsive-grid-desktop-col-gap = the space between rows, defaults to 0 for both screen sizes.
    // defaults to 0 because many elements in our app, such as form fields provide their own spacing :(
    $desktop-row-gap: var(--responsive-grid-desktop-row-gap, 0);
    $mobile-row-gap: var(--responsive-grid-mobile-row-gap, 0);

    display: flex;
    flex-direction: column;
    row-gap: $desktop-row-gap;

    @media (max-width: $medium-breakpoint) {
        row-gap: $mobile-row-gap;
    }

    .row {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax($minimum-column-width, $maximum-column-width));
        column-gap: $desktop-column-gap;

        @media (max-width: $medium-breakpoint) {
            column-gap: $mobile-column-gap;
        }

        // Rows with an odd number of children will use flex instead of grid. This ensures no orphaned elements when wrapping occurs
        &:has(> *:nth-child(odd):last-child) {
            display: flex;

            > * {
                flex: 1;
            }

            // By default wrap to column mode at the medium breakpoint, chained classes .wrap-small and .wrap-large are available below to modify this
            @media (max-width: $medium-breakpoint) {
                flex-direction: column;
            }

            &.wrap-small {
                flex-direction: row;

                @media (max-width: $small-breakpoint) {
                    flex-direction: column;
                }
            }

            &.wrap-large {
                flex-direction: row;

                @media (max-width: $large-breakpoint) {
                    flex-direction: column;
                }
            }
        }
    }
}

// Used to layout a grid of cards, can be modified using the provided CSS variables
.card-grid {
    --card-bottom-margin: 0;
    $card-grid-gap: var(--card-grid-gap, #{calc-spacing(2)});
    $card-width: var(--card-grid-width, 336px);
    $card-height: var(--card-grid-height); // Unset by default allows for dynamic height

    display: grid;
    gap: $card-grid-gap;
    grid-template-columns: repeat(auto-fill, $card-width);
    grid-template-rows: repeat(auto-fill, $card-height);

    @media (max-width: $large-breakpoint) {
        display: flex;
        flex-direction: column;
    }
}

@each $type in $spacing-types {
    .#{$type}-auto {
        #{$type}: auto;
    }

    @each $key, $directions in $spacing-mapped-directions {
        $rule: calc-rule($type, $key, auto);
        .#{$rule} {
            @each $direction in $directions {
                #{$type}-#{$direction}: auto;
            }
        }
    }

    @each $direction in $spacing-directions {
        $rule: calc-rule($type, $direction, auto);

        .#{$rule} {
            #{$type}-#{$direction}: auto;
        }
    }
}

/**
 * This builds the utility classes for common spacings.
 * The numeric value is an 8px multiplier.
 * E.g: margin-top-3, margin-x-1, margin-right-2
 * Use responsive sizes to override styles for mobile etc. (`small`, `medium`, `large`, `xl`)
 * E.g: `small-margin-top-1 margin-top-5` Uses 8px margin-top up to small breakpoint, then 40px margin-top.
 */
@for $i from 0 through $space-length {
    @each $type in $spacing-types {
        /**
         * Core padding/margin styles
         */
        .#{$type}-#{$i} {
            #{$type}: calc-spacing($i);
        }
        @each $size in $responsive-sizes {
            @include breakpoint($size) {
                // use `body` to override default class (more specific element ref)
                body .#{$size}-#{$type}-#{$i} {
                    #{$type}: calc-spacing($i);
                }
            }
        }

        /**
         * x, y, left, right spacing
         */
        @each $key, $directions in $spacing-mapped-directions {
            $rule: calc-rule($type, $key, $i);
            .#{$rule} {
                @each $direction in $directions {
                    #{$type}-#{$direction}: calc-spacing($i);
                }
            }
            @each $size in $responsive-sizes {
                @include breakpoint($size) {
                    // use `body` to override default class (more specific element ref)
                    body .#{$size}-#{$rule} {
                        @each $direction in $directions {
                            #{$type}-#{$direction}: calc-spacing($i);
                        }
                    }
                }
            }
        }

        /**
         * top/left/bottom/right spacing
         */
        @each $direction in $spacing-directions {
            $rule: calc-rule($type, $direction, $i);

            .#{$rule} {
                #{$type}-#{$direction}: calc-spacing($i);
            }

            @each $size in $responsive-sizes {
                @include breakpoint($size) {
                    // use `body` to override default class (more specific element ref)
                    body .#{$size}-#{$rule} {
                        #{$type}-#{$direction}: calc-spacing($i);
                    }
                }
            }
        }
    }
}
