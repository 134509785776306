@import 'variables';

/**
 * Variables
 */
$tableHeadFontSize: 15px;
$tableHeadFontWeight: 500;
$tableHeadFontColor: $neutral-darkest;
$borderRadius: $segment-border-radius;

$tableCellPadding: 27px 18px;
$tableCellFontColor: $neutral-darker;
$tableCellFontSize: $font-size;
$tableCellFontWeight: $font-weight;

$tableRowDivider: 1px solid color-mix(in hsl, var(--grey-400), transparent 75%);
$tableRowHoverBackground: color-mix(in hsl, var(--grey-400), transparent 84%);

$tableRowDividerLeft: linear-gradient(
    to right,
    var(--white) fff 30px,
    color-mix(in hsl, var(--grey-400), transparent 75%) 30px,
    color-mix(in hsl, var(--grey-400), transparent 75%) 100%
);
$tableRowDividerRight: linear-gradient(
    to left,
    var(--white) fff 30px,
    color-mix(in hsl, var(--grey-400), transparent 75%) 30px,
    color-mix(in hsl, var(--grey-400), transparent 75%) 100%
);

/**
 * Styles
 */
table.ui.table {
    border: none;
    border-radius: $segment-border-radius;
    background: transparent;
    thead {
        background: none;
        tr {
            overflow: visible;
            background: none;
            th {
                padding: $tableCellPadding;
                padding-top: 10px;
                text-transform: lowercase;
                &:first-letter {
                    text-transform: uppercase;
                }
            }
            th,
            th a {
                background: none;
                font-size: $tableHeadFontSize;
                font-weight: $tableHeadFontWeight;
                color: $tableHeadFontColor;
            }
            th:first-of-type {
                //gradient half border effect
                //border-image: $tableRowDividerLeft;
                //border-image-slice: 1;
            }
            th:last-of-type {
                //gradient half border effect
                //border-image: $tableRowDividerRight;
                //border-image-slice: 1;
            }
            th:not(:first-of-type):not(:last-of-type) {
                border-bottom: $tableRowDivider;
            }
            // Removed the tableRowDivider images because safari sucks
            th {
                border-bottom: $tableRowDivider;
            }
        }
    }
    tbody {
        tr:first-of-type {
            td {
                border-top: none !important;
                border-image: none !important;
            }
        }
        td:first-of-type {
            font-weight: 500 !important;
            div {
                font-weight: 500 !important;
            }
            white-space: nowrap;
            i {
                padding-right: 2em;
            }
        }
        td:first-of-type {
            //gradient half border effect
            //border-image: $tableRowDividerLeft;
            //border-image-slice: 1;
        }
        td:last-of-type {
            //gradient half border effect
            //border-image: $tableRowDividerRight;
            //border-image-slice: 1;
        }
        td:not(:first-of-type):not(:last-of-type) {
            border-top: $tableRowDivider;
        }
        // Removed the tableRowDivider images because safari sucks
        td {
            border-top: $tableRowDivider;
        }
        td {
            padding: $tableCellPadding;
            color: $tableCellFontColor;
            font-size: $tableCellFontSize;
            font-weight: $tableCellFontWeight;
            overflow: visible;
            & > mat-icon {
                color: $tableCellFontColor;
            }
        }
    }

    &.selectable {
        tbody {
            tr:hover,
            td:hover {
                outline: none;
                cursor: pointer;
            }
            tr:hover {
                background: $tableRowHoverBackground !important;
                td {
                    background: none !important;
                }
            }
        }
    }

    .other-income-input-wrapper {
        --field-default-background-color: var(--grey-400);

        cursor: pointer;

        input {
            cursor: pointer;
        }

        &:hover,
        &:focus {
            --field-background-color: var(--primary-100);
            --field-default-input-font-color: var(--primary-600);
            --field-default-border-color: var(--primary-600);

            html.dark & {
                --field-background-color: var(--grey-500);
                --field-default-input-font-color: var(--grey-900);
                --field-default-border-color: var(--grey-900);
            }
        }
    }
}

.ui.table.section {
    background: none;
}
