.ui.flex.grid {
    display: flex;
    flex-flow: row;
    .column {
        width: auto;
        display: block;
        &.stretch {
            flex-grow: 1;
        }
    }
}
