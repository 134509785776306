@import 'platform-colors';
@import 'variables';

.flatpickr-calendar {
    border: none !important;
    box-shadow: 1px 3px 10px $neutral-light;
    border-radius: 3px !important;
    color: $neutral-darkest !important;

    .cur-month,
    .cur-year {
        color: $header-font-color !important;
    }
}
