@import 'variables';

.donut {
    width: 24px;
    height: 24px;
    border-width: 3px;
    border-style: solid;
    border-radius: 100%;

    @media screen and (max-width: $large-breakpoint) {
        width: 0px;
        height: 0px;
        border-width: 4px;
    }
}
