$primary-dark: var(--primary-700);
$primary: var(--primary-600);
$primary-light: var(--primary-500);
$primary-lighter: var(--primary-300);
$primary-lightest: var(--primary-200);
$primary-max-lightest: var(--primary-100);
$black: var(--black);
$neutral-darkest: var(--grey-900);
$neutral-darker: var(--grey-800);
$neutral-dark: var(--grey-700);
$neutral: var(--grey-500);
$neutral-light: var(--grey-400);
$neutral-lighter: var(--grey-200);
$neutral-lightest: var(--grey-100);
$white: var(--white);
$primary-font: $neutral-darkest;
$secondary-font: $neutral-dark;
$tertiary-font: var(--grey-600);
$highlight-dark: var(--highlight-700);
$highlight: var(--highlight-600);
$highlight-light: var(--highlight-500);
$highlight-lighter: var(--highlight-400);
$highlight-lightest: var(--highlight-300);
$success-dark: var(--success-700);
$success: var(--success-500);
$success-light: var(--success-400);
$success-lighter: var(--success-300);
$success-lightest: var(--success-100);
$error-dark: var(--error-900);
$error: var(--error-800);
$error-light: var(--error-700);
$error-lighter: var(--error-400);
$error-lightest: var(--error-100);
$warning-dark: var(--warning-900);
$warning: var(--warning-600);
$warning-light: var(--warning-500);
$warning-lighter: var(--warning-400);
$warning-lightest: var(--warning-200);
$secondary: $neutral;
$tertiary: $white;

$platform-color-map: (
    primary-dark: $primary-dark,
    primary: $primary,
    primary-light: $primary-light,
    primary-lighter: $primary-lighter,
    primary-lightest: $primary-lightest,
    primary-max-lightest: $primary-max-lightest,
    black: $black,
    neutral-darkest: $neutral-darkest,
    neutral-darker: $neutral-darker,
    neutral-dark: $neutral-dark,
    neutral: $neutral,
    neutral-light: $neutral-light,
    neutral-lighter: $neutral-lighter,
    neutral-lightest: $neutral-lightest,
    white: $white,
    primary-font: $primary-font,
    secondary-font: $secondary-font,
    highlight-dark: $highlight-dark,
    highlight: $highlight,
    highlight-light: $highlight-light,
    highlight-lighter: $highlight-lighter,
    highlight-lightest: $highlight-lightest,
    success-dark: $success-dark,
    success: $success,
    success-light: $success-light,
    success-lighter: $success-lighter,
    success-lightest: $success-lightest,
    error-dark: $error-dark,
    error: $error,
    error-light: $error-light,
    error-lighter: $error-lighter,
    error-lightest: $error-lightest,
    warning-dark: $warning-dark,
    warning: $warning,
    warning-light: $warning-light,
    warning-lighter: $warning-lighter,
    warning-lightest: $warning-lightest,
    secondary: $secondary,
    tertiary: $tertiary,
);
