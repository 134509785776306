@import 'platform-colors';

/**
 * UI List
 */
.ui.selectable.list {
    .item {
        padding: 0.5em !important;
        border-radius: 5px;
    }
    .item:hover {
        cursor: pointer;
        .header {
            color: var(--primary-500);
        }
    }
    .content {
        line-height: 1.5;
        color: var(--grey-500);
        .header {
            color: var(--grey-500);
        }
    }
}

.ui.list {
    .content {
        line-height: 1.5;
        color: var(--grey-500);
        .header {
            color: var(--grey-500);
        }
        .description {
            color: $secondary-font;
        }
    }
}

.ui.selection.list > .item {
    color: var(--grey-900);
}
