@import 'platform-colors';

.ui.loader {
    top: 130px;
    transform: rotate(180deg);
}

.ui.loader:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.5em 0 0 -0.5em;
    width: 3em;
    height: 3em;
    -webkit-animation: segment-spin 0.6s linear;
    animation: segment-spin 0.6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-style: solid;
    border-width: 0.2em;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
    visibility: visible;
    z-index: 101;
    border-color: $primary color-mix(in hsl, var(--black), transparent 95%)
        color-mix(in hsl, var(--black), transparent 95%) color-mix(in hsl, var(--black), transparent 95%) !important;
    border-width: 7px;
}

.ui.loader:before {
    display: none;
}

.ui.loading.segment,
.ui.loading.form {
    min-height: 150px;
}

.ui.loading.segment:before {
    background-color: var(--spinner-overlay-default-color);
}

.ui.loading.segment:after {
    top: 130px;
    border-color: $primary color-mix(in hsl, var(--black), transparent 95%)
        color-mix(in hsl, var(--black), transparent 95%) color-mix(in hsl, var(--black), transparent 95%);
    border-width: 7px;
    z-index: 998;
}

.ui.loading.segment:not(.basic):after {
    top: 50%;
}

.ui.loading.form:after {
    border-color: $primary color-mix(in hsl, var(--black), transparent 95%);
    border-width: 7px;
    z-index: 999;
}
