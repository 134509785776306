.point-w,
.point-e,
.point-s,
.cropper-face,
.point-n,
.point-ne,
.point-nw,
.point-sw {
    background-color: transparent !important;
}

.cropper-view-box {
    border-radius: 100%;
    outline-color: transparent;
}

.cropper-line {
    display: none !important;
}

.cropper-center:after,
.cropper-center:before {
    -webkit-transform: scale(3.5);
}

.cropper-point.point-se {
    right: 12%;
    bottom: 12%;
    -webkit-transform: translate(100%, 100%);
    border-radius: 100%;
}
