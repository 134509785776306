@import 'constants';
@import 'variables';

/*
Common styles for all Angular Material Chips use
*/
mat-chip,
mat-chip-option,
mat-chip-row {
    &.mat-primary.mat-mdc-standard-chip {
        --mdc-chip-elevated-container-color: var(--chip-background-color, var(--grey-200));
        --mdc-chip-label-text-color: var(--chip-font-color, var(--grey-900));
        --mdc-chip-label-text-size: var(--chip-font-size, #{$font-size});
        --mdc-chip-label-text-weight: var(--chip-font-weight, #{$font-regular});
        --mdc-chip-container-shape-radius: var(--chip-border-radius, #{$borderRadius});
        --mdc-chip-container-height: var(--chip-height, 24px);

        padding: var(--chip-padding, calc-spacing(0.5) calc-spacing(1));

        .mat-mdc-chip-action {
            padding: 0;
        }

        .mat-mdc-chip-remove {
            margin-left: calc-spacing(1);
            margin-right: 0;
            color: var(--chip-remove-color, var(--primary-600));
            border-radius: 4px;
            opacity: 0.7;
            background-color: var(--card-background-color);
            transition:
                opacity,
                background-color 0.3s ease;

            &:hover {
                opacity: 1;
                background-color: var(--grey-300);
            }
        }
    }
}

/*
mat-chip-option is used for selectable chips such as the filters in inbox-viewer
*/
mat-chip-option.mat-primary.mat-mdc-standard-chip {
    &.selected {
        --mdc-chip-elevated-container-color: var(--chip-selected-background-color);
        --mdc-chip-label-text-color: var(--chip-selected-font-color);

        &:hover {
            --mdc-chip-elevated-container-color: var(--chip-selected-hover-background-color);
            --mdc-chip-label-text-color: var(--chip-selected-hover-font-color);
        }
    }

    &:hover {
        --mdc-chip-elevated-container-color: var(--chip-hover-background-color);
        --mdc-chip-label-text-color: var(--chip-hover-font-color);
    }
}

/*
mat-chip-grid is used for add/remove chip functionality in a form field (ie. chips-autocomplete)
*/
mat-chip-grid {
    input.mat-mdc-input-element {
        margin-top: 0;
    }

    &.read-only.mat-chip-listbox-disabled {
        mat-chip {
            color: var(--grey-900);
            opacity: 1;
        }
    }
}

/*
mat-chip-row is used within a mat-chip-grid
*/
mat-chip-row {
    // The chip itself does not need to appear clickable unless if it is the remove button
    .mat-mdc-chip-action:not(.mat-mdc-chip-remove) {
        cursor: default;
    }

    // Unfortunately the library doesn't allow for leading remove buttons so we need to add this in to put the remove button before the text
    .mdc-evolution-chip__cell--trailing:has(.mat-mdc-chip-remove) {
        order: -1;

        .mat-mdc-chip-remove {
            margin-left: 0;
            margin-right: calc-spacing(1);
        }
    }
}
