mat-select.mat-mdc-select {
    --mat-select-placeholder-text-color: var(--select-default-placeholder-font-color);

    display: flex;

    .mat-mdc-select-trigger {
        height: var(--field-input-line-height);
    }

    // Hides the default arrow provided by Angular Material
    .mat-mdc-select-arrow {
        opacity: 0;
    }

    // Overrides Angular Material chevron with our own
    &::after {
        background: url('/assets/material-override-icons/chevron-down-neutral-darker.svg') center no-repeat;
        content: '';
        height: var(--select-chevron-size);
        width: var(--select-chevron-size);
        position: absolute;
        right: 0;
        top: calc((var(--field-min-height) - var(--select-chevron-size)) / 2);
        transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    }

    // Rotates chevron when open
    &[aria-expanded='true']::after {
        transform: rotate(180deg);
    }

    // Errored chevron variant
    &.mat-mdc-select-invalid {
        &:after {
            background: url('/assets/material-override-icons/chevron-down-error.svg') center no-repeat;
        }
    }

    // Disabled chevron variant
    &.mat-mdc-select-disabled:not(.read-only) {
        &:after {
            background: url('/assets/material-override-icons/chevron-down-neutral-dark.svg') center no-repeat;
        }
    }
}

.mat-mdc-select-panel.mat-primary {
    --mat-select-panel-background-color: var(--select-panel-background-color);

    margin-top: var(--select-panel-vertical-gap);
    border-radius: var(--select-panel-border-radius) !important;
    box-shadow: var(--select-panel-box-shadow);
}

mat-option.mat-mdc-option {
    --mat-option-label-text-size: var(--select-item-font-size);
    --mat-option-label-text-line-height: var(--select-item-line-height);
    --mat-option-selected-state-layer-color: var(--select-item-active-background-color);
    --mat-option-selected-state-label-text-color: var(--select-item-active-font-color);
    --mat-option-label-text-color: var(--select-item-default-font-color);

    // hover/focus state
    --mat-option-focus-state-layer-color: var(--select-item-hover-background-color);
    --mat-option-hover-state-layer-color: var(--select-item-hover-background-color);

    // Ensures child text elements also receive the font color styling from their parent in the case where there are nested elements in options
    &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text > * {
        color: inherit;
    }

    &.mat-mdc-option-active {
        --mat-option-label-text-color: var(--select-item-hover-font-color);
    }

    &[aria-selected='true'] {
        background-color: var(--mat-option-selected-state-layer-color);
    }

    // Don't persist highlighted active background color on multi-select dropdowns
    &:has(mat-pseudo-checkbox) {
        --mat-option-selected-state-layer-color: var(--select-item-default-background-color);
    }

    min-height: unset;
    padding: var(--select-item-padding);

    // Pseudo-checkbox styling for multi-select component, matches existing checkbox styling
    mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox {
        width: var(--checkbox-size);
        height: var(--checkbox-size);
        border-width: var(--checkbox-border-width);
        border-radius: var(--checkbox-border-radius);
        border-color: var(--checkbox-default-border-color);
        margin-right: var(--checkbox-label-gap);

        &.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
            background-color: var(--primary-600);
            border-color: var(--checkbox-selected-background-color);
        }

        &::after {
            border-width: var(--checkbox-border-width);
        }
    }
}
