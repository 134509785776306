@import 'responsive';

/* @deprecated - Do not use app-grid or its child column classes

1. The specificity is INCORRECT. The point of a grid system is to have smaller breakpoints override styles for larger breakpoints by having greater specificity. This does not work in the current implementation and results in broken responsive layouts
2. It uses negative margins, these are difficult to work around and should typically be avoided
3. There's simply better tools available now than what was required when this was written. You can write your own layout simply with display: grid or display: flex

*/

// Grid
$columnCount: 12;
$gutterSize: 24px;
$mobileGutterSize: 16px;

.app-grid {
    display: grid;
    grid-template-columns: repeat($columnCount, 1fr);
    column-gap: $gutterSize;

    @media (max-width: $small-breakpoint) {
        column-gap: $mobileGutterSize;
    }

    row-gap: 0;
    & > * {
        grid-column: span $columnCount;
    }

    &.centered {
        align-items: center;
    }

    &.grid-centered {
        align-items: center;
    }

    .justify-start {
        justify-self: start;
    }

    .justify-end {
        justify-self: end;
    }

    &.half-gutter {
        column-gap: calc(#{$gutterSize}/ 2);
    }
    .no-gutter {
        margin-left: -$gutterSize;
        margin-right: -$gutterSize;
    }
}

@for $i from 1 through $columnCount {
    $rule: '.columns-#{$i}';
    #{unquote($rule)} {
        grid-column: span #{$i};
    }

    #{unquote($rule)}-end {
        grid-column: span unquote('#{$i} / -1');
    }
}

@media (max-width: $xxl-breakpoint) {
    @for $i from 1 through $columnCount {
        .xxl-columns-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@media (max-width: $xl-breakpoint) {
    @for $i from 1 through $columnCount {
        .xl-columns-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@media (max-width: $large-breakpoint) {
    @for $i from 1 through $columnCount {
        .large-columns-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@media (max-width: $medium-breakpoint) {
    @for $i from 1 through $columnCount {
        .medium-columns-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@media (max-width: $small-breakpoint) {
    @for $i from 1 through $columnCount {
        .small-columns-#{$i} {
            grid-column: span #{$i};
        }
    }
}
