:root {
    --select-chevron-size: 16px;

    --select-default-placeholder-font-color: var(--field-default-placeholder-font-color);

    --select-panel-background-color: var(--dropdown-background-color);
    --select-panel-border: none;
    --select-panel-border-radius: var(--dropdown-border-radius);
    --select-panel-default-font-color: var(--grey-900);
    --select-panel-box-shadow: var(--dropdown-box-shadow);
    --select-panel-vertical-gap: 0.25rem;

    --select-item-font-size: var(--body-font-size);
    --select-item-line-height: 20px;
    --select-item-padding: 10px 16px;
    /* Default state */
    --select-item-default-font-color: var(--grey-900);
    /* Active state */
    --select-item-active-background-color: var(--dropdown-item-selected-background-color);
    --select-item-active-font-color: var(--dropdown-item-selected-font-color);
    /* Hover state */
    --select-item-hover-background-color: var(--dropdown-item-hover-background-color);
    --select-item-hover-font-color: var(--dropdown-item-hover-font-color);
}
