@import 'variables';
@import 'platform-colors';
@import 'utils/spacing';

/**
 * UI Card
 */

// Vars
$border: $segment-border;
$box-shadow: var(--segment-box-shadow);

$cardExtraContentPadding: 30px;

$cardContentPadding: 17px;

$cardContentHeaderSize: 15px;
$cardContentHeaderColor: $neutral-darker;

$cardContentDescriptionSize: 12px;
$cardContentDescriptionColor: $neutral-darker;
$cardContentDescriptionWeight: 300;

$card-border-radius: $border-radius;

.ui.cards .card {
    background-color: var(--card-background-color);
    border: $border;
    box-shadow: $box-shadow;
    border-radius: $card-border-radius;
    margin: calc-spacing(2) calc-spacing(1);
    .content:not(.extra) {
        border-top: none !important;
        padding: $cardExtraContentPadding;
        margin: 0;
        .ui.header {
            font-weight: normal;
        }
        .description {
            color: $neutral-darkest;
            font-weight: 300;
        }
        &:not(:first-child) {
            padding-top: 0;
        }
    }
    .extra.content {
        padding: $cardContentPadding;
        margin: 0;
        .header:not(.ui) {
            font-weight: normal;
            color: $cardContentHeaderColor;
            font-size: $cardContentHeaderSize;
        }
        .description {
            color: $cardContentDescriptionColor;
            font-size: $cardContentDescriptionSize;
            font-weight: $cardContentDescriptionWeight;
        }
    }

    html.dark & {
        border: 1px solid var(--grey-900);
    }
}

.ui.horizontal.cards {
    .card {
        .extra.content {
            border: none !important;
            .description {
                font-size: $font-size;
            }
        }
    }
}

.ui.link.cards .card {
    &:hover {
        transform: none;
        border: $border;
        border-radius: $card-border-radius;
        box-shadow: $box-shadow;
    }
}
