.ui-sortable-placeholder {
    border: 1px dashed var(--grey-400);
    min-height: 56px;
    width: 100%;
}

.ui-sortable-handle {
    border-radius: 0 !important;
}

.ui-sortable-placeholder {
    border: 1px dashed var(--primary-500);
    background: color-mix(in hsl, var(--primary-500), transparent);
}
