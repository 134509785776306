.quill {
    ol > li,
    ul > li {
        list-style-type: none;
    }
    li::before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
    }
    li:not(.ql-direction-rtl)::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
    }
    ol li:not(.ql-direction-rtl),
    ul li:not(.ql-direction-rtl) {
        padding-left: 1.5em;
    }
    ul > li::before {
        content: '\2022';
    }
    ol li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-0;
    }
    ol li:before {
        content: counter(list-0, decimal) '. ';
    }
    ol li.ql-indent-1 {
        counter-increment: list-1;
    }
    ol li.ql-indent-1:before {
        content: counter(list-1, lower-alpha) '. ';
    }
    ol li.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-2 {
        counter-increment: list-2;
    }
    ol li.ql-indent-2:before {
        content: counter(list-2, lower-roman) '. ';
    }
    ol li.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-3 {
        counter-increment: list-3;
    }
    ol li.ql-indent-3:before {
        content: counter(list-3, decimal) '. ';
    }
    ol li.ql-indent-3 {
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-4 {
        counter-increment: list-4;
    }
    ol li.ql-indent-4:before {
        content: counter(list-4, lower-alpha) '. ';
    }
    ol li.ql-indent-4 {
        counter-reset: list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-5 {
        counter-increment: list-5;
    }
    ol li.ql-indent-5:before {
        content: counter(list-5, lower-roman) '. ';
    }
    ol li.ql-indent-5 {
        counter-reset: list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-6 {
        counter-increment: list-6;
    }
    ol li.ql-indent-6:before {
        content: counter(list-6, decimal) '. ';
    }
    ol li.ql-indent-6 {
        counter-reset: list-7 list-8 list-9;
    }
    ol li.ql-indent-7 {
        counter-increment: list-7;
    }
    ol li.ql-indent-7:before {
        content: counter(list-7, lower-alpha) '. ';
    }
    ol li.ql-indent-7 {
        counter-reset: list-8 list-9;
    }
    ol li.ql-indent-8 {
        counter-increment: list-8;
    }
    ol li.ql-indent-8:before {
        content: counter(list-8, lower-roman) '. ';
    }
    ol li.ql-indent-8 {
        counter-reset: list-9;
    }
    ol li.ql-indent-9 {
        counter-increment: list-9;
    }
    ol li.ql-indent-9:before {
        content: counter(list-9, decimal) '. ';
    }
    .ql-indent-1:not(.ql-direction-rtl) {
        padding-left: 3em;
    }
    li.ql-indent-1:not(.ql-direction-rtl) {
        padding-left: 4.5em;
    }
    .ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 6em;
    }
    li.ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 7.5em;
    }
    .ql-indent-3:not(.ql-direction-rtl) {
        padding-left: 9em;
    }
    li.ql-indent-3:not(.ql-direction-rtl) {
        padding-left: 10.5em;
    }
    .ql-indent-4:not(.ql-direction-rtl) {
        padding-left: 12em;
    }
    li.ql-indent-4:not(.ql-direction-rtl) {
        padding-left: 13.5em;
    }
    .ql-indent-5:not(.ql-direction-rtl) {
        padding-left: 15em;
    }
    li.ql-indent-5:not(.ql-direction-rtl) {
        padding-left: 16.5em;
    }
    .ql-indent-6:not(.ql-direction-rtl) {
        padding-left: 18em;
    }
    li.ql-indent-6:not(.ql-direction-rtl) {
        padding-left: 19.5em;
    }
    .ql-indent-7:not(.ql-direction-rtl) {
        padding-left: 21em;
    }
    li.ql-indent-7:not(.ql-direction-rtl) {
        padding-left: 22.5em;
    }
    .ql-indent-8:not(.ql-direction-rtl) {
        padding-left: 24em;
    }
    li.ql-indent-8:not(.ql-direction-rtl) {
        padding-left: 25.5em;
    }
    .ql-indent-9:not(.ql-direction-rtl) {
        padding-left: 27em;
    }
    li.ql-indent-9:not(.ql-direction-rtl) {
        padding-left: 28.5em;
    }
}
