@use 'sass:map';
$responsive-sizes: 'small', 'medium', 'large', 'xl';
$small-breakpoint: 680px;
$inclusive-small-breakpoint: $small-breakpoint + 1px; // Used to ensure there are no screen sizes that fall between media queries
$medium-breakpoint: 768px;
$inclusive-medium-breakpoint: $medium-breakpoint + 1px;
$large-breakpoint: 1024px;
$inclusive-large-breakpoint: $large-breakpoint - 1px;
$xl-breakpoint: 1280px;
$xxl-breakpoint: 1536px;
$responsive-breakpoint-map: (
    'small': $small-breakpoint,
    'medium': $medium-breakpoint,
    'large': $large-breakpoint,
    'xl': $xl-breakpoint,
);

$mobileH1: 24px;
$mobileH2: 16px;

@mixin mobile {
    @media only screen and (max-width: $medium-breakpoint) {
        @content;
    }
}

@mixin breakpoint($size) {
    @media only screen and (max-width: map.get($responsive-breakpoint-map, $size)) {
        @content;
    }
}
