.settings {
    display: flex;
    flex-direction: column;
    gap: calc-spacing(4);

    @media (max-width: $medium-breakpoint) {
        gap: calc-spacing(2);
    }
}

.setting {
    display: flex;
    gap: calc-spacing(1);
    padding: calc-spacing(2) 0;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    &-description {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: calc-spacing(0.5);
        :not(h3) {
            font-weight: $font-light;
        }
        h3 {
            display: flex;
            align-items: center;
            margin: 0;
            ui-badge {
                margin-left: calc-spacing(1);
            }

            @media (max-width: $medium-breakpoint) {
                flex-direction: column-reverse;
                align-items: flex-start;
                ui-badge {
                    margin-left: 0;
                    margin-bottom: calc-spacing(1);
                }
            }
        }
    }

    &.disabled * {
        color: $secondary-font;
    }
}
