@import 'variables';
@import 'platform-colors';

/**
 * UI Calendar (Angular/Bootstrap Calendar)
 */

$calendar-header-color: $neutral;
$calendar-event-color: $primary;

.month-calendar-container {
    .ui.segment {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}

mwl-calendar-month-cell {
    outline: none;
}

mwl-calendar-week-view .cal-week-view {
    .cal-day-headers,
    .cal-day-headers .cal-header {
        border: none !important;
    }

    .cal-day-headers .cal-header:hover {
        background: var(--card-background-color) !important;
    }

    .cal-header {
        b {
            color: $calendar-header-color;
        }
    }

    .cal-header.cal-weekend {
        span {
            color: $calendar-header-color;
        }
    }

    .cal-header.cal-today {
        background-color: var(--white);

        b {
            color: $primary;
        }

        span {
            color: $calendar-header-color;
        }
    }

    .cal-event {
        background: var(--grey-100);

        .cal-event-title {
            color: $calendar-event-color;
        }
    }
}

mwl-calendar-month-view {
    .cal-month-view .cal-header {
        text-align: center;
        font-size: 1.1em;
        // center the header, taking into account ui segment
        margin-bottom: 15px;
        margin-top: -5px;
    }

    .cal-month-view .cal-cell-row:hover {
        background-color: var(--card-background-color);
    }

    .cal-month-view .cal-header .cal-cell {
        font-weight: 600;
        color: $secondary-font;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
    }

    .cal-month-view .cal-header .cal-today {
        color: $primary;
    }

    .cal-month-view .cal-cell-row .cal-cell:hover,
    .cal-month-view .cal-cell.cal-has-events.cal-open {
    }

    .cal-month-view .cal-days {
        background-color: var(--card-background-color);
    }

    .cal-month-view .cal-cell-top {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .cal-month-view .cal-cell-holiday {
        font-size: 0.9em;
        color: var(--white);
        background-color: $primary;
        border-radius: 4px;
        padding-left: 5px;
        width: 90%;
        line-height: 15px;
        margin-top: 3px;
        margin-bottom: 3px;

        p {
            color: var(--white);
        }
    }

    .cal-month-view .cal-cell-no-holiday {
        width: 90%;
    }

    .cal-month-view .cal-cell-row {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
    }

    .cal-month-view .cal-cell {
        float: left;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 0;
    }

    /* This is the actual cell */
    .cal-month-view {
        .cal-cell-row {
            .cal-day-cell {
                flex: 1;
                background-color: var(--card-background-color);
                border-top: 1px solid var(--grey-400);
                border-left: 1px solid var(--grey-400);
                height: 160px;
                cursor: pointer;
                transition: all 0.4s;

                &.cal-today {
                    .cal-day-number {
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        align-items: center;
                        color: var(--white);
                        background-color: $primary;
                        border-radius: 50%;
                    }
                }

                &:first-child {
                    border-left: none;
                }
            }
        }
    }

    .cal-month-view .cal-day-badge {
        display: none;
    }

    .cal-month-view .cal-day-number {
        font-size: 1em;
        margin-top: 10px;
        margin-left: 10px;
        float: left;
        margin-bottom: 0;
        font-weight: 600 !important;
        color: var(--grey-800);
        opacity: 0.75;
        width: 24px;
        height: 24px;
    }

    .cal-month-view .cal-events {
        flex: 4;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-bottom: 3px;
    }

    .cal-month-view .cal-event {
        max-width: 95%;
        margin: 2px 0;
        display: flex;
        justify-content: flex-start;
        border-left: solid 2px $primary;
    }

    .cal-month-view .cal-event > .cal-event-title {
        padding: calc-spacing(0.5);
        color: var(--primary-800);
        font-size: $font-size;
        line-height: $line-height;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .cal-month-view .cal-event > .cal-event-more {
        color: var(--grey-800);
        text-align: center;
    }
}

.cal-event {
}

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
    cursor: pointer;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.4;
    cursor: default;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
}

.cal-month-view .cal-day-cell.cal-today {
}

.cal-month-view .cal-day-cell.cal-today:hover {
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
}

.cal-month-view .cal-day-cell.cal-today {
    position: relative;
}

//.cal-month-view .cal-day-cell.cal-today::after {
//    content: "Today";
//    z-index: 200;
//    position: absolute;
//    top: 3px;
//    right: 5px;
//    font-weight: 600;
//    color: $secondary-font;
//    opacity: 0.75;
//}

.cal-month-view .cal-day-cell.cal-drag-over {
    background-color: var(--grey-400) !important;
}

.cal-month-view .cal-open-day-events {
    padding: 15px;
    color: var(--white);
    background-color: var(--grey-800);
    -webkit-box-shadow: inset 0 0 15px 0 color-mix(in hsl, var(--black), transparent 50%);
    box-shadow: inset 0 0 15px 0 color-mix(in hsl, var(--black), transparent 50%);
}

.cal-month-view .cal-open-day-events .cal-event {
    position: relative;
    top: 2px;
}

.cal-month-view .cal-event-title {
    color: var(--white);
}

.cal-month-view .cal-out-month .cal-day-badge,
.cal-month-view .cal-out-month .cal-event {
    opacity: 0.3;
}

.cal-week-view .cal-day-headers {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    margin-bottom: 3px;
    border: 1px solid var(--grey-400);
    margin-left: 2px;
    margin-right: 2px;
}

.cal-week-view .cal-day-headers .cal-header {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    padding: 5px;
}

.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    border-right: 1px solid var(--grey-400);
}

.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
    background-color: var(--grey-200);
}

.cal-week-view .cal-day-headers span {
    font-weight: normal;
    opacity: 0.5;
}

.cal-week-view .cal-events-row {
    position: relative;
    height: 33px;
}

.cal-week-view .cal-event-container {
    display: inline-block;
    position: absolute;
}

.cal-week-view .cal-event {
    padding: 0 10px;
    font-size: 12px;
    margin-left: 2px;
    margin-right: 2px;
    height: 30px;
    line-height: 30px;
}

.cal-week-view .cal-draggable {
    cursor: move;
}

.cal-week-view .cal-starts-within-week .cal-event {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.cal-week-view .cal-ends-within-week .cal-event {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cal-week-view .cal-header.cal-today {
    background-color: var(--success-100);
    color: $primary;
}

.cal-week-view .cal-header.cal-weekend span {
    color: $error;
}

.cal-week-view .cal-event,
.cal-week-view .cal-header {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cal-day-view {
    /* stylelint-disable-next-line selector-type-no-unknown */
    /* stylelint-disable-next-line selector-type-no-unknown */
}

.cal-day-view .cal-hour-rows {
    width: 100%;
    border: solid 1px var(--grey-400);
    overflow-x: scroll;
    position: relative;
}

.cal-day-view .cal-hour:nth-child(odd) {
    background-color: var(--error-100);
}

.cal-day-view mwl-calendar-day-view-hour-segment {
    -ms-touch-action: initial !important;
    touch-action: initial !important;
    -webkit-user-select: initial !important;
    -moz-user-select: initial !important;
    -ms-user-select: initial !important;
    user-select: initial !important;
    -webkit-user-drag: initial !important;
}

.cal-day-view mwl-calendar-day-view-hour-segment,
.cal-day-view .cal-hour-segment {
    display: block;
}

.cal-day-view .cal-hour-segment::after {
    content: '\A0';
}

.cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed var(--grey-400);
}

.cal-day-view .cal-time {
    font-weight: bold;
    padding-top: 5px;
    width: 70px;
    text-align: center;
}

.cal-day-view .cal-hour-segment.cal-after-hour-start .cal-time {
    display: none;
}

.cal-day-view .cal-hour-segment:hover,
.cal-day-view .cal-drag-over .cal-hour-segment {
    background-color: var(--grey-200);
}

.cal-day-view .cal-event-container {
    position: absolute;
}

.cal-day-view .cal-event {
    border: solid 1px;
    padding: 5px;
    font-size: 12px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.cal-day-view .cal-draggable {
    cursor: move;
}

.cal-day-view .cal-starts-within-day .cal-event {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.cal-day-view .cal-ends-within-day .cal-event {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cal-day-view .cal-all-day-event {
    padding: 8px;
    border: solid 1px;
}

.cal-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    font-size: 11px;
    word-wrap: break-word;
    opacity: 0.9;
}

.cal-tooltip.cal-tooltip-top {
    padding: 5px 0;
    margin-top: -3px;
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: var(--black);
}

.cal-tooltip.cal-tooltip-right {
    padding: 0 5px;
    margin-left: 3px;
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: var(--black);
}

.cal-tooltip.cal-tooltip-bottom {
    padding: 5px 0;
    margin-top: 3px;
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: var(--black);
}

.cal-tooltip.cal-tooltip-left {
    padding: 0 5px;
    margin-left: -3px;
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: var(--black);
}

.cal-tooltip-inner {
    width: 300px;
    padding: 3px 8px;
    color: var(--black);
    text-align: center;
    background-color: var(--card-background-color);
    border-radius: 0.25rem;
}

.cal-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: var(--black);
    border-style: solid;
}
