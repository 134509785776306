@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-ExtraLight.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-ExtraLight.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-ExtraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-ExtraLightItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-ExtraLightItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-ExtraLightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-Light.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-Light.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-LightItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-LightItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-Regular.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-Regular.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-RegularItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-RegularItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-Medium.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-Medium.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-MediumItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-MediumItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-SemiBold.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-SemiBold.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-SemiBoldItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-SemiBoldItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-SemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-Bold.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-Bold.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-BoldItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-BoldItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-ExtraBold.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-ExtraBold.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'PPMori';
    src:
        url('/humi-design-library-assets/font/mori/WOFF2/PPMori-ExtraBoldItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/mori/WOFF/PPMori-ExtraBoldItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/mori/OTF/PPMori-ExtraBoldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-X-Light.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-X-Light.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-X-Light.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-X-LightItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-X-LightItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-X-LightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-Light.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-Light.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-LightItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-LightItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-Regular.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-Regular.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-RegularItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-RegularItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-Medium.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-Medium.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-MediumItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-MediumItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-Bold.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-Bold.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-BoldItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-BoldItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-X-Bold.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-X-Bold.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-X-Bold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Tenon';
    src:
        url('/humi-design-library-assets/font/tenon/WOFF2/Tenon-X-BoldItalic.woff2') format('woff2'),
        url('/humi-design-library-assets/font/tenon/WOFF/Tenon-X-BoldItalic.woff') format('woff'),
        url('/humi-design-library-assets/font/tenon/OTF/Tenon-X-BoldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}
