@import 'responsive';
@import 'mixins/loadable';

/*======================================
*          Utility classes            *
*                                     *
* This file is for utility classes    *
* that can be re-used throughout the *
* application. If your element has    *
* more than a couple utility classes  *
* please  consider using a bespoke    *
* class in your component's style     *
* sheet.                              *
======================================*/

// Contains all width and height classes (.w-sm-50, .h-100, etc.)
@import 'classes/dimensions';

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-none {
    display: none !important;
}

.d-contents {
    display: contents;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

.loadable {
    @include loadable;
}

.text {
    &-small {
        font-size: $font-small;
        line-height: $line-height-small;
    }

    &-large {
        font-size: $font-large;
        line-height: $line-height-large;
    }
}

.font-secondary {
    color: $secondary-font;
}

.bg-white {
    background-color: $white;

    html.dark & {
        background-color: transparent; // In dark mode we want to just use the default background color
    }
}

input[type='number'].no-arrow-buttons {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    -moz-appearance: textfield;
}

// Allows for a button with a dropdown menu to rotate the chevron upon opening
*[aria-haspopup='menu'].animate-chevron {
    mat-icon[svgicon='chevronDown'] {
        display: flex;
        transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
        transform: rotate(var(--chevron-angle, 0deg));
    }

    &[aria-expanded='true'] {
        --chevron-angle: 180deg;
    }
}

/*
** Screen-reader only class. This is used when you want to hide an element from the visual DOM but have it available to screen-readers
** for accessibility purposes.
*/
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    /* stylelint-disable-next-line unit-disallowed-list */
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
