@import 'responsive';
@import 'platform-colors';
@import 'typography';
@import 'utils/spacing';
@import 'mixins/loadable';

/* This file contains global styling of basic HTML tags
Many of these are legacy styles that were implemented long ago, modifications should be done carefully with extensive testing site-wide.
*/

*:not(i, .ag-icon, .ag-checkbox-input-wrapper) {
    font-family: $font-family;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
td,
th,
div,
button,
input,
a {
    color: $font-color;
}

@for $i from 1 through 6 {
    h#{$i} {
        @extend %heading-#{$i};
    }
}

p,
li,
td,
th,
div,
button,
input {
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &.empty-state {
        padding: 2em !important;
        color: $neutral-darkest !important;
        font-weight: 300 !important;
        border: 1px dashed color-mix(in hsl, var(--grey-700), transparent 86%) !important; // $neutral-darker
    }
}

a {
    color: var(--link-font-color);
    transition: 0.2s;
    text-decoration: underline;
    text-decoration-color: var(--link-font-color);

    &:hover {
        color: var(--link-hover-font-color);
        text-decoration: underline;
        text-decoration-color: var(--link-hover-font-color);
    }
}

ui-button,
[role='navigation'],
.mat-menu-content,
app-deprecated-filter,
.ui.dropdown .menu {
    a,
    a:hover {
        text-decoration: none;
    }
}

hr {
    height: 1px;
    border: none;
    background: $neutral-light;
    padding: 0;
    margin: 0 0 calc-spacing(2) 0;

    &.lighter {
        background: $neutral-lighter;
    }
}

body,
html {
    font-size: $font-size;
}

body {
    background: $background;
    height: auto;
    font-family: 'App';
    text-rendering: geometricPrecision !important;

    &.data-bg {
        &::after {
            content: ' ';
            width: 500px;
            height: 400px;
            background-image: url('/assets/img/data-bg.png');
            position: absolute;
            top: $header-height;
            right: 0;
            opacity: 0.5;
        }
    }
}

form {
    @include loadable;
}

/* Change Autocomplete styles */
%autofill {
    caret-color: $primary-font;
    -webkit-text-fill-color: $primary-font;

    /* You cannot override a browser style-sheet's definition for color, or background color.
    Therefore, the only way to provide custom styling on autofill background is to use an inset box-shadow */
    box-shadow:
        0 0 0 100vw var(--field-autofill-background-color) inset,
        // This color has transparency, therefore it's necessary to add the card-background-color below it to still override the browser default autofill background
        0 0 0 100vw var(--card-background-color) inset !important;
}

// Highlighted text styling, works in both light/dark mode
*::selection {
    color: $font-color;
    background: var(--grey-400);
}

input,
textarea,
select {
    &:-webkit-autofill {
        @extend %autofill;

        &:hover,
        &:focus {
            @extend %autofill;
        }

        &[aria-invalid='true'] {
            box-shadow: 0 0 0 100vw var(--field-errored-background-color) inset !important;
        }
    }

    &::selection {
        color: $font-color;
    }
}
