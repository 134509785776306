:root {
    --chip-font-color: var(--grey-700);
    --chip-hover-font-color: var(--grey-800);
    --chip-background-color: transparent;
    --chip-hover-background-color: var(--grey-200);

    --chip-selected-font-color: var(--primary-600);
    --chip-selected-hover-font-color: var(--primary-800);
    --chip-selected-background-color: var(--primary-100);
    --chip-selected-hover-background-color: var(--primary-200);
}

html.dark {
    --chip-font-color: var(--grey-900);
    --chip-hover-font-color: var(--white);
    --chip-background-color: transparent;
    --chip-hover-background-color: var(--grey-100);

    --chip-selected-font-color: var(--grey-900);
    --chip-selected-hover-font-color: var(--white);
    --chip-selected-background-color: var(--grey-400);
    --chip-selected-hover-background-color: var(--grey-500);
}
