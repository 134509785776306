:root {
    --humi-logo-font-color-light: #fafaf8;
    --humi-logo-font-color-dark: #060928;

    --humi-logo-font-color: var(--humi-logo-font-color-dark);
}

html.dark {
    --humi-logo-font-color: var(--humi-logo-font-color-light);
}
