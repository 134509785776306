:root {
    --badge-default-font-size: 0.75rem;
    --badge-default-font-weight: 600;
    --badge-default-padding: 0.25rem 0.5rem;
    --badge-default-border-radius: 58px;

    --badge-default-font-color: var(--grey-800);
    --badge-default-background-color: var(--grey-400);

    --badge-informative-font-color: var(--primary-700);
    --badge-informative-background-color: var(--primary-200);

    --badge-danger-font-color: var(--error-700);
    --badge-danger-background-color: var(--error-200);

    --badge-warning-font-color: var(--warning-900);
    --badge-warning-background-color: var(--warning-200);

    --badge-success-font-color: var(--success-800);
    --badge-success-background-color: var(--success-200);
}

html.humi-rebrand {
    --badge-default-font-color: var(--primary-700);
    --badge-default-background-color: var(--primary-200);

    --badge-informative-font-color: var(--informative-800);
    --badge-informative-background-color: var(--informative-200);
}

html.dark {
    --badge-default-font-color: var(--primary-900);
    --badge-default-background-color: var(--primary-500);

    --badge-informative-font-color: var(--informative-900);
    --badge-informative-background-color: var(--informative-300);

    --badge-danger-font-color: var(--error-900);
    --badge-danger-background-color: var(--error-400);

    --badge-warning-font-color: var(--grey-100);
    --badge-warning-background-color: var(--warning-400);

    --badge-success-font-color: var(--success-900);
    --badge-success-background-color: var(--success-400);
}
