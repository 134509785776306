@import 'variables';

/**
 * Variables
 */
$statisticCircleBorderColor: $neutral-light;

/**
 * UI Statistics
 */
.ui.statistics {
    padding-top: 2em;
    padding-bottom: 1em;
    margin-bottom: 0;
    .statistic {
        position: relative;
    }
    .statistic::after {
        width: 100px;
        height: 100px;
        content: ' ';
        border: 1px solid $statisticCircleBorderColor;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .statistic > .value {
        font-size: 26px !important;
        color: $neutral-darker;
        font-weight: 400;
    }
    .statistic > .label {
        font-size: 14px !important;
        font-weight: 300;
        text-transform: capitalize;
    }
    .statistic[ng-reflect-router-link]:hover {
        cursor: pointer;
        user-select: none;
    }
}
