/**
         * UI Message
         */
.ui.message {
    font-weight: 300;
    font-family: $font-family;

    border-radius: $large-border-radius;

    &.basic {
        border: none;
        background: none;
        padding: 0;
        box-shadow: none;
        font-size: 15px;
        line-height: 20px;
        color: var(--grey-700);
    }

    &.warning.message {
        background-color: var(--warning-200);
        color: var(--warning-900);

        .header,
        p {
            color: var(--warning-900);
        }
    }

    &.tutorial {
        opacity: 0.9;
        border: none;
        background: var(--primary-500);
        padding: 0;
        box-shadow: none;
        font-size: 15px;
        line-height: 20px;
        color: var(--white);
        border: none;
        padding: 1em;
        margin-top: -1em;
        margin-bottom: 2em;
        box-shadow: 0px 4px 7px 1px var(--grey-400);

        &.negative {
            background-color: var(--highlight-600);
            a {
                color: var(--white);
            }
        }

        & > .close.icon {
            top: 10px !important;
            font-size: 1.3em !important;
            font-weight: normal !important;
        }

        .ui.header {
            color: var(--white);
        }

        .ui.divider {
            margin-bottom: 10px;
            border-color: color-mix(in hsl, var(--white), transparent 71%);
        }

        p {
            margin-top: 0;
            color: var(--white);
        }

        ul {
            padding-left: 1em;
            li {
                list-style: none;
                .svg-inline--fa {
                    padding-right: 0.25em;
                }
                margin-bottom: 5px;
                a {
                    color: var(--white);
                }
            }
        }
    }
}
