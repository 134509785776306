@import '../mixins/line-clamp';
@import '../utils/spacing';

// A class that can be used to show a vertically stacked label with its value underneath
.vertical-label {
    display: flex;
    flex-direction: column;
    row-gap: calc-spacing(1);

    @media (max-width: $large-breakpoint) {
        row-gap: calc-spacing(0.5);
    }

    // the vertical-labels class can be used if the individual labels need to be stacked in a vertical container
    &s {
        display: flex;
        flex-direction: column;
        gap: calc-spacing(2);

        @media (max-width: $large-breakpoint) {
            gap: calc-spacing(1.5);
        }
    }

    // First element (label)
    > :first-child {
        color: $secondary-font;
        font-weight: $font-medium;
        font-size: $font-size;
        line-height: 16px;
    }
    // Second element (value)
    > :nth-child(2) {
        font-weight: $font-regular;
        font-size: $font-size;
        line-height: 16px;

        // These values rarely have much text in them, but we default to truncating in case they cause unexpected layout shifts (ie. someone has a name of 100+ characters).
        // The default can be overridden in the case of text that needs more room (ie. a description that contains many lines of text)
        &:not(.allow-overflow) {
            // Only allow two lines of text before truncating with ellipses. Keeps from custom values overflowing endlessly
            @include line-clamp(2);
        }
    }
}
