@import 'platform-colors';

$background: $primary-max-lightest;

mat-calendar {
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: color-mix(
        in hsl,
        var(--primary-500),
        transparent 60%
    );
    --mat-datepicker-calendar-date-focus-state-background-color: color-mix(in hsl, var(--primary-500), transparent 70%);
    --mat-datepicker-calendar-date-hover-state-background-color: color-mix(in hsl, var(--primary-500), transparent 70%);
    --mat-datepicker-calendar-date-in-range-state-background-color: color-mix(
        in hsl,
        var(--primary-500),
        transparent 80%
    );

    .mat-calendar-body-in-preview,
    .mat-calendar-body-preview-end,
    .mat-calendar-body-preview-start {
        &:not(.mat-calendar-body-in-range) {
            .mat-calendar-body-cell-preview {
                background-color: $background;
            }
        }
        .mat-calendar-body-cell-preview {
            // Get rid of dashes cell preview
            border: none;
        }
    }

    .mat-calendar-body-preview-end .mat-calendar-body-cell-content {
        border: 1px solid $primary;
    }

    .mat-calendar-body-preview-end .mat-calendar-body-cell-preview,
    .mat-calendar-body-cell-preview {
        top: 0%;
        height: 100%;
        width: 100%;
    }

    .mat-calendar-body-cell::before {
        height: 100%;
        top: 0%;
    }

    .mat-calendar-body-in-range::before {
        background-color: $background;
    }
}

:root {
    --mat-datepicker-calendar-container-background-color: var(--date-picker-default-background-color);
    --mat-datepicker-calendar-container-text-color: var(--date-picker-default-font-color);
    --mat-datepicker-calendar-date-text-color: var(--date-picker-default-font-color);
    --mat-datepicker-calendar-body-label-text-color: var(--date-picker-default-font-color);
    --mat-datepicker-calendar-header-text-color: var(--date-picker-default-font-color);
    --mat-datepicker-calendar-period-button-icon-color: var(--date-picker-default-font-color);
    --mat-datepicker-calendar-navigation-button-icon-color: var(--date-picker-default-font-color);
    --mat-datepicker-calendar-date-today-outline-color: var(--date-picker-today-outline-color);
    --mat-datepicker-range-input-separator-color: var(--date-picker-default-font-color);
    --mdc-icon-button-icon-color: var(--grey-600);
}

mat-datepicker-content.mat-datepicker-content {
    border-radius: var(--date-picker-default-border-radius);

    .mat-mdc-button {
        &.mat-unthemed {
            --mdc-text-button-label-text-color: var(--date-picker-default-font-color);
        }

        --mat-mdc-button-persistent-ripple-color: var(--grey-900);
    }

    .mat-mdc-icon-button {
        --mat-mdc-button-persistent-ripple-color: var(--grey-900);
    }

    --mat-datepicker-calendar-date-disabled-state-text-color: var(--date-picker-disabled-font-color);

    box-shadow: var(--date-picker-box-shadow);
}
