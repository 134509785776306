@import 'platform-colors';

/**
 * UI Statistics
 */
.ui.menu {
    &:not(.secondary) .item {
        color: $font-color;
    }

    &.pagination {
        box-shadow: none;
        border: 1px solid color-mix(in hsl, var(--grey-500), transparent 50%);

        .item {
            &:before {
                background: color-mix(in hsl, var(--grey-500), transparent 50%);
            }
            color: $font-color;
            justify-content: center;
        }
    }

    &.tabular {
        border-color: var(--grey-400);
        .item.table {
            background: var(--grey-100);
        }
        .item {
            color: var(--grey-500);
        }
        .active.item {
            border-color: var(--grey-400);
        }
        .item.table {
            background: var(--grey-100);
        }
    }

    .ui.dropdown .menu > .item:hover {
        // important is necessary due to semantic using !important in their own style definition
        background: var(--dropdown-item-hover-background-color) !important;
        color: var(--dropdown-item-hover-font-color) !important;
    }

    &.secondary {
        a.item,
        .item {
            color: $neutral-darkest;
            line-height: 1;
            font-size: $font-size;
            border-radius: 18px;
            padding: 0.5em 1em 0.5em 1em;
            transition: 0.3s;
            font-weight: $font-weight;
        }
        a.item:hover {
            transition: 0.3s;
        }
        a.item.active {
            background-color: var(--grey-200);
            color: $primary-font;
            font-weight: $font-medium;
        }

        .ui.dropdown.item .menu {
            border: none;
            box-shadow: 1px 3px 10px var(--grey-400);
            border-radius: 3px !important;
            padding: 0;
            margin-left: 0.5em;
            .item {
                padding: 1em 2em !important;
                color: $primary-font !important;
                width: 100%;
                text-align: left;
                font-size: 14px !important;
                color: $primary-font !important;
                border-radius: 0;
                i {
                    margin-right: 1em;
                    opacity: 0.8;
                    color: $secondary-font;
                    font-size: 1em !important;
                    position: relative;
                }

                &:hover {
                    background: var(--grey-100) !important;
                }
            }
        }
    }
}
